<template>
  <div class="team">
    <v-container >
      <v-layout row wrap>
         <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
					<template v-if="Errors.length > 0">
						<v-card>
							<v-card-title class="red--text text-h6">Kiện hàng lỗi</v-card-title>
							<v-card-text>
								<div v-for="item in Errors" :key="item._id" class="red--text">
									Kiện: {{item.Code}} - Khách: {{item.Customer.Username}}
								</div>
							</v-card-text>
						</v-card>
						<br />
					</template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách lô hàng</h3>
                <v-spacer />
                <v-btn color="green" dark @click="boxAdd = true" v-if="shipment.Status === 'dang_xu_ly' && loginInfo._perm !== 'sale' && loginInfo._perm !== 'warehousevn'">Thêm</v-btn>
              </v-toolbar>
              <v-card-title>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="2"
                    xl="2"
                    v-if="packages.length > 0"
                  >
                    <v-autocomplete
                        v-model="PackagesFilter"
                        :items="KHGFilter"
                        item-value="_id"
                        item-text="Code"
                        label="Lọc theo mã kiện hàng gộp"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="2"
                    xl="2"
                    v-if="loginInfo._perm !== 'warehouse'"
                  >
                    <v-select
                      v-model="FilterMobile.Status"
                      :items="StatusList"
                      item-value="_id"
                      item-text="Code"
                      label="Lọc theo trạng thái"
                      @change="UpdateFilter"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="2"
                    xl="2"
                    v-if="selected.length > 0 && shipment.Status === 'dang_xu_ly'"
                  >
                    <v-btn
                      color="blue"
                      dark
                      :disabled="selected.length === 0 || shipment.Status !== 'dang_xu_ly'"
                      @click="boxGop = true"
                    >
                      Gộp kiện hàng ({{selected.length}} Kiện -  KL: {{TongKLGop}} Kg - TT: {{TongTTGop}})
                    </v-btn>
                  </v-col>
									<v-col
										cols="auto"
										v-if="ChangeStatusPackages.length > 0"
									>
										<v-btn
											color="orange"
											dark
											@click="doToggleFastStatus()"
										>
											<v-icon left>mdi-alert-circle-outline</v-icon>
											Cập nhật nhanh trạng thái ({{ChangeStatusPackages.length}} Kiện)
										</v-btn>
									</v-col>
                  <v-spacer />
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Tìm kiếm"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
                <v-flex xs12 style="overflow:auto">
                  <v-data-table
                    v-model="selected"
                    :loading="loading"
                    :headers="headers"
                    :items="DanhSachMoi"
                    :search="search"
                    loading-text="Đang tải dữ liệu..."
                    :footer-props="{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50,100,150]}"
                    :items-per-page="100"
                    item-key="_id"
                    height="750px"
                    fixed-header
                    :show-select="loginInfo._perm === 'admin' || loginInfo._perm === 'checker' "
                    @toggle-select-all="selectAllToggle"
                  >
                  <template v-slot:[`body.append`] v-if="loginInfo._perm !== 'warehouse' &&  loginInfo._perm !== 'warehousevn' && !isMobile()">
                    <FilterList :StatusList="StatusList" :TongNW="TotalNW" :TongGW="TongGW" :TongSL="TongSL" :TongKH="DanhSachMoi.length" :CustomerList="FilterCustomerList" :SaleList="FilterSaleList" :TongCuocSale="TongCuocSale" :TongCuocGoc="TongCuocGoc" :TongLoiNhuan="TongLoiNhuan"  />
                  </template>
                  <template v-slot:[`body.prepend`] v-if="loginInfo._perm !== 'warehouse' &&  loginInfo._perm !== 'warehousevn' && !isMobile()">
                    <FilterList :StatusList="StatusList" :TongNW="TotalNW"  :TongGW="TongGW" :TongSL="TongSL" :TongKH="DanhSachMoi.length" :CustomerList="FilterCustomerList" :SaleList="FilterSaleList" :TongCuocSale="TongCuocSale" :TongCuocGoc="TongCuocGoc" :TongLoiNhuan="TongLoiNhuan"  />
                  </template>
                    <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                      <v-simple-checkbox
                        :ripple="false"
                        :value="isSelected"
                        @input="select($event)"
                        v-if="!item.Disabled"
                      />
                    </template>
                    <template v-slot:[`header.data-table-select`]></template>
										<template v-slot:[`item.TO`]="{ item }">
											<template v-if="item.TO.length > 0">
												<v-menu offset-y>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															color="blue"
															dark
															v-bind="attrs"
															v-on="on"
															text
														>
															{{item.TO[0].Name}}
														</v-btn>
													</template>
														<v-list>
															<template v-for="(itemData, index) in item['TO'][0]['Items']">
																<div :key="index">
																	<template v-if="!Array.isArray(itemData.Tracking)">
																		<v-list-item>
																			<v-list-item-title>{{ itemData.Tracking }}</v-list-item-title>
																		</v-list-item>
																	</template>
																	<template v-else>
																		<v-list-item
																			v-for="(trackData, index2) in itemData.Tracking.map(d=>d.Tracking)"
																			:key="index2"
																		>
																			<v-list-item-title>{{ trackData }}</v-list-item-title>
																		</v-list-item>
																	</template>
																</div>
															</template>
														</v-list>
												</v-menu>
											</template>
										</template>
                    <template v-slot:[`item.Customer.Username`]="{ item }">
                      <v-btn color="primary" @click="doOpenChangeKH(item)" text x-large v-if="loginInfo._perm === 'admin'">
                        {{item.Customer.Username}}
                      </v-btn>
                      <template v-else>
                        {{item.Customer.Username}}
                      </template>
                    </template>
										<template v-slot:[`item.SentInvoice`]="{ item }">
											<v-checkbox
												v-model="item.SentInvoice"
												:label="item.SentInvoice ? 'Đã gửi':'Chưa gửi'"
												:color="item.SentInvoice ? 'green':'orange'"
												:background-color="item.SentInvoice ? 'green':'orange'"
												readonly
												dark
											/>
										</template>
                    <template v-slot:[`item.Code`]="{ item }">
<!--											<template v-if="LimitSale">
												<v-menu offset-y>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															color="blue"
															v-bind="attrs"
															v-on="on"
															text
															x-large
														>
															{{item.Code}}
														</v-btn>
													</template>
													<v-list>
														<v-list-item>
															<v-list-item-title>
																<v-btn
																	outlined
																	color="blue"
																	dark
																	@click="Load(item._id,item.Code)"
																>
																	Chi tiết
																</v-btn>
															</v-list-item-title>
														</v-list-item>
														<v-list-item>
															<v-list-item-title>
																<v-btn
																	outlined
																	color="green"
																	dark
																	@click="doPrint(item)"
																>
																	In nhãn
																</v-btn>
															</v-list-item-title>
														</v-list-item>
													</v-list>
												</v-menu>
											</template>-->
<!--											<template v-else>
											</template>-->
											<v-btn
												color="blue"
												text
												x-large
												@click="Load(item._id,item.Code)" x-large
											>
												{{item.Code}}
											</v-btn>
                    </template>
                    <template v-slot:[`item.Customer.NameJP`]="{ item }">
                      {{ item.Customer.NameJP }}
                      <p>{{item.Customer.PhoneJP}}</p>
                    </template>
                    <template v-slot:[`item.Shipping.Name`]="{ item }">
											<v-btn
												color="blue"
												dark
												@click="doOpenShipping(item)"
												small
											>
												{{item.Shipping.Name}}
											</v-btn>
                      <v-divider />
                      <p>{{item.Shipping.Phone}}</p>
                    </template>
                    <template v-slot:[`item.TaiHinh`]="{ item }">
                        <v-img
                        max-height="200"
                        max-width="200"
                        aspect-ratio="1"
                        :src="LinkHinhAnh(item)"
                      >
                        <v-card-title primary-title style="height: 100px; position: relative">
                            <v-btn
                              color="primary"
                              class="text-none"
                              rounded
                              depressed
                              :loading="isSelecting[item._id]"
                              :disabled="loadingUpload[item._id]"
                              @click="onButtonClick(item)"
                            >
                              <v-icon left>
                                cloud_upload
                              </v-icon>
                              {{ uploadButtonText }}
                            </v-btn>
                        </v-card-title>
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                        <v-row
                          v-if="loadingUpload[item._id]"
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </v-img>
                    </template>
                    <template v-slot:[`item.totalWeight`]="{ item }">
                        {{ item.totalWeight }}
                    </template>
                    <template v-slot:[`item.LoiNhuan`]="{ item }">
                      {{ DoiCuoc(item.LoiNhuan) }}
                    </template>
                    <template v-slot:[`item.GiaSale`]="{ item }">
                      {{ DoiCuoc(item.GiaSale) }}
                    </template>
                    <template v-slot:[`item.GiaGoc`]="{ item }">
                      {{ DoiCuoc(item.GiaGoc) }}
                    </template>
                    <template v-slot:[`item.TongPhuThu`]="{ item }">
                      <v-btn
                        color="primary"
                        dark
                        @click="MoBoxPhuThu(item)"
                      >
                        {{ DoiCuoc(item.TongPhuThu) }}
                      </v-btn>
                    </template>
                    <template v-slot:[`item.Phi`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-btn
                              color="primary"
                              @click="UpdateCuoc(item)"
                            >
                              {{ DoiCuoc(item.Phi) }}
                            </v-btn>
                          </span>
                        </template>
                        <span>Bấm vào đây để đổi phí cho kiện hàng này</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.Status`]="{ item }">
											<v-row align="center">
												<v-checkbox
													class="ma-1 justify-center align-center d-inline"
													v-model="ChangeStatusPackages"
													:value="item._id"
													v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'sale'"
												/>
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<span
															v-bind="attrs"
															v-on="on"
														>
														<v-btn
															:color="paymentStatus[item.Status].color"
															dark
															v-bind="attrs"
															v-on="on"
															@click="updateStatus(item)"
															class="ma-1"
														>
															{{ paymentStatus[item.Status].text }}
														</v-btn>
														</span>
													</template>
													<span>Bấm vào đây để đổi trạng thái cho kiện hàng này</span>
												</v-tooltip>
											</v-row>
										</template>
                    <template v-slot:[`item.Note`]="{ item }">
                      <v-tooltip bottom v-if="loginInfo._perm === 'checker' || loginInfo._perm === 'admin' || loginInfo._perm === 'manager'">
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
														class="d-inline"
                          >
                          <v-btn
                            color="blue"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="updateNote(item)"
                            x-small
                          >
                            Cập nhật
                          </v-btn>
                          </span>
                        </template>
                        <span>Bấm vào đây để đổi ghi chú cho kiện hàng này</span>
                      </v-tooltip>
                      <br>
                      {{item.Note}}
                    </template>
                    <template v-slot:[`item.Tracking`]="{ item }">
                      <CheckTracking :track="item.Tracking" :delivered="item.Delivered" :submit="Track" title="Kiểm tra tracking" v-if="item.Tracking">
                        <Tracking :items="TrackData" />
                        <v-overlay
                          absolute
                          :value="loadingTracking"
                        >
                          <v-progress-circular
                            indeterminate
                            color="primary"
                            v-if="loadingTracking"
                          />
                        </v-overlay>
                      </CheckTracking>
                      <v-btn v-else
                        color="blue"
                        @click="Tracking(item)"
                        dark
                        v-if="loginInfo._perm !== 'sale'"
                      >
                        Cập nhật Tracking
                      </v-btn>
                    </template>
                    <template v-slot:[`item._id`]="{ item }">
											<Manage :item="item" :controls="CustomControls(item)" />
                    </template>
                  </v-data-table>
                </v-flex>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách lô hàng</h3>
                <v-spacer />
                <v-btn color="green" dark @click="boxAdd = true" v-if="shipment.Status === 'dang_xu_ly' && loginInfo._perm !== 'sale' && loginInfo._perm !== 'warehousevn'">Thêm</v-btn>
              </v-toolbar>
            </v-card>
            <v-overlay
              absolute
              :value="loading"
            >
              <v-progress-circular
                indeterminate
                color="primary"
                v-if="loading"
              />
            </v-overlay>
          </template>
         </v-flex>
      </v-layout>
    </v-container>
    <Add :enable.sync="boxAdd" :process="ProcessAdd" :loadingAdd="loadingAdd" :submitForm="Them" :Sales="sales" :shipmentData="shipment" :lastest="lastest" />
    <TrackBox :enable.sync="boxTracking" :tracking.sync="CapNhat.Tracking" :providers.sync="CapNhat.ShipmentProvider" :loading="loadingTracking" :submitForm="CapNhatTrack" :Pickup="PickupPackage" />
    <BoxGop :enable.sync="boxGop" :list.sync="packages" :loading="loadingGop" :submitForm="GopKienHang" />
    <input type="file" id="file" ref="uploader" v-on:change="handleFileUpload" v-on:blur="cancelUpload()" class="hide" />
     <!-- <image-uploader
      ref="uploader"
      class="hide"
      outputFormat="file"
      :maxHeight="450"
      :maxWidth="450"
      :quality="0.7"
      :preview=false
      accept="image/*"
      doNotResize="['gif', 'svg']"
      @input="setImage"
    /> -->
    <vue-easy-lightbox
      moveDisabled
      :visible="visible"
      :imgs="imgs"
      :index="0"
      @hide="handleHide"
    />
    <v-dialog
      v-model="boxStatus"
      width="500"
    >
      <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            <v-btn
              icon
              dark
              @click="boxStatus = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Cập nhật trạng thái</v-toolbar-title>
          </v-toolbar>

        <v-card-title>Thông tin trạng thái</v-card-title>
        <v-card-text>
          <v-select
            v-model="PaymentStatus.Status"
            :items="PaymentStatusList"
            item-value="code"
            item-text="text"
            label="Trạng thái"
          />
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn 
            v-if="PaymentStatus.ImageTT"
            color="primary"
            @click="showHoaDon(PaymentStatus.ImageTT)"
          >
            Xem hóa đơn
          </v-btn>
          <v-spacer />
					<v-btn
						color="primary"
						@click="doPaymentUpdateList"
						v-if="PaymentStatus.ListID"
					>
						Lưu ({{PaymentStatus.ListID.length}}) kiện
					</v-btn>
          <v-btn
            color="primary"
            @click="doPaymentUpdate"
						v-if="PaymentStatus.ID"
          >
            Lưu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="boxDoiCuoc"
      max-width="900px"
      ransition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="boxDoiCuoc = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Đổi cước: {{ DoiCuocData.Name }}</v-toolbar-title>
          </v-toolbar>
        <v-card-title>
          ĐỔI CƯỚC
        </v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><h3>Cước</h3></v-list-item-title>
              <v-list-item-subtitle>
                <v-form v-model="valid">
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-currency-field 
                        v-model="DoiCuocData.Cuoc"
                        label="Cước"
                        :hint="ThongBaoDoiCuoc"
                        persistent-hint
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      v-if="loginInfo._perm === 'admin'"
                    >
                      <v-currency-field 
                        v-model="DoiCuocData.CuocGoc"
                        label="Cước gốc"
                        hint="Đây là cước bán cho Sale"
                        persistent-hint
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-btn color="green" dark @click="CapNhatCuoc()" :disabled="loginInfo._perm === 'warehouse'">
                        Đổi
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
      <v-overlay
        absolute
        :value="loadingCuoc"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loadingCuoc"
        />
      </v-overlay>
    </v-dialog>
    <v-dialog
      v-model="boxPhuThu"
      max-width="900px"
      ransition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="boxPhuThu = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>PHỤ THU</v-toolbar-title>
        </v-toolbar>
        <v-card-title>Thêm phụ thu</v-card-title>
        <v-card-text>
          <v-responsive max-height="400px" class="overflow-y-auto">
            <template
              v-for="(item,index) in PhuThu"
            >
              <v-card 
                :key="index"
                flat
              >
                <v-card-title>Phụ thu #{{index+1}}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="PhuThu[index].Muc"
                        label="Mục phụ thu"
                        :rules="[required('Mục phụ thu')]"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                    >
                      <v-currency-field
                        v-model="PhuThu[index].Phi"
                        label="Phí"
                        :rules="[required('Phí')]"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-subtitle><v-divider /></v-card-subtitle>
                <v-card-actions><v-btn fab x-small color="red" dark @click="XoaMucPhuThu(index)"><v-icon>mdi-delete</v-icon></v-btn></v-card-actions>
              </v-card>
            </template>
          </v-responsive>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="ThemMucPhuThu" small color="primary" dark>
            <v-icon left>mdi-shape-rectangle-plus</v-icon>
            Thêm mục phụ thu
          </v-btn>
          <v-spacer />
          <v-btn color="green" dark @click="UpdatePhuThu">
            Cập nhật
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="800px"
      persistent
      v-model="boxNote"
    >
      <v-card>
        <v-card-title>Ghi chú</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="GhiChu"
            label="Nội dung"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="boxNote=false"
            dark
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            @click="doSaveNote"
            color="green"
            dark
          >
            Cập nhật
          </v-btn>
        </v-card-actions>
      </v-card>
      <loading :status="loading" />
    </v-dialog>
    <v-dialog
      max-width="800px"
      persistent
      v-model="boxChangeKH"
    >
      <v-form v-model="validChangeKH">
        <v-card>
          <v-card-title>Đổi Khách Hàng - {{UpdateMaKH.Code}}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="auto"
                >
                <v-autocomplete
                  v-model="UpdateMaKH.MaKhachHang"
                  :items="Customers"
                  item-value="id"
                  item-text="Username"
                  label="Mã khách hàng"
                  />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="pa-2"
              dark
              color="green"
              :disabled="!validChangeKH"
              @click="doChangeKH"
              >
              Cập nhật
            </v-btn>
            <v-spacer />
            <v-btn
              class="pa-2"
              dark
              @click="boxChangeKH = false"
            >
              Đóng
            </v-btn>
          </v-card-actions>
          <loading :status="loadingChangeKH" />
        </v-card>
      </v-form>
    </v-dialog>
		<v-dialog
			v-model="boxShipping"
			width="900px"
		>
			<v-card>
				<v-card-title>
					Thông tin người nhận: {{ShippingData.Code}}
				</v-card-title>
				<v-card-text>
					<v-select
						v-model="newShippingFromAddress"
						:items="ShippingData['Customer']['AddressVN']"
						item-text="Name"
						item-value="_id"
						label="Chọn từ sổ địa chỉ"
						class="mx-3"
						v-if="ShippingData['Customer'] && ShippingData['Customer']['AddressVN'].length > 0"
						@change="doUpdateShippingFromAddress"
					/>
					<v-row v-if="ShippingData.Shipping">
						<v-col
							cols=12
							lg=4
							xl=4
						>
							<v-text-field
								v-model="ShippingData.Shipping.Name"
								label="Tên người nhận"
							/>
						</v-col>
						<v-col
							cols=12
							lg=4
							xl=4
						>
							<v-text-field
								v-model="ShippingData.Shipping.Phone"
								label="Số điện thoại"
							/>
						</v-col>
						<v-col
							cols=12
							lg=6
							xl=6
						>
							<v-textarea
								v-model="ShippingData.Shipping.Address"
								label="Địa chỉ"
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions v-if="ShippingData.Shipping">
					<v-btn dark color="green" @click="UpdateNewShipping(ShippingData._id,ShippingData.Shipping)" v-if="(shipment.Status === 'dang_xu_ly' && !ShippingData.Tracking) || loginInfo._perm === 'admin'">
						Cập nhật
					</v-btn>
					<v-spacer />
					<v-btn
						@click="boxShipping = false"
					>
						Đóng
					</v-btn>
				</v-card-actions>
			</v-card>
			<loading :status="loadingShipping" />
		</v-dialog>
		<loading :status="loadingStatus" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import {InvoiceStatusByShipment} from '@/plugins/query/global'
import Query from '@/plugins/query/package'
import { Request } from '@/plugins/helper/fetch'
import { Status } from '@/plugins/helper/dstatus'
import { DoiTien } from '@/plugins/helper/string'
import { payment } from '@/plugins/helper/status'
import { Cuoc } from '@/plugins/helper/tinhtien.js'
import imageProcess from '@/plugins/helper/images'
import Add from '@/components/package/add.vue'
import TrackBox from '@/components/package/tracking.vue'
import Manage from '@/components/base/thaotac.vue'
import Dialog from '@/components/base/Dialog.vue'
import BoxGop from '@/components/package/gop.vue'
import validations from '@/plugins/helper/validations'
import Tracking from '@/components/tracking/List.vue'
import CheckTracking from '@/components/tracking/Check.vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import loading from '@/components/base/Loading.vue'
import FilterList from '@/components/package/filter.vue'
import {getPDF} from "../plugins/query/package";
import printJS from "print-js";
// import ImageUploader from '@/components/image-composer/ImageUploader.vue'
export default {
  name: 'package',
  components: {
    // ImageUploader,
    Add,
    Manage,
    Dialog,
    TrackBox,
    BoxGop,
    Tracking,
    CheckTracking,
    VueEasyLightbox,
    loading,
    FilterList,
  },
  data () {
    return {
			ChangeStatusPackages: [],
			AddressBox: [],
			newShippingFromAddress: null,
      CustomerFilter: null,
      StatusFilter: null,
      PackagesFilter: null,
      hasImage: false,
      selectedFile: null,
      progress: 0,
      // isSelecting: false,
      isSelecting: {
      },
      DoiCuocData: {
        Cuoc:0,
        Min: 0,
        ID: undefined,
      },
      FilterMobile:{
        Customer:null,
        Sale:null,
        Status:null,
      },
      StatusList:[
        {_id:null,Code:'Tất cả'},
        {_id:'cho_thanh_toan',Code:'Chờ thanh toán'},
        {_id:'dang_xu_ly',Code:'Đang xử lí'},
				{_id:'moi_thanh_toan',Code:'Mới thanh toán'},
        {_id:'da_thanh_toan',Code:'Đã thanh toán'},
				{_id:'da_nhan_tai_kho',Code:'Đã nhận hàng tại kho'},
      ],
      isSelectingID: undefined,
      visible: false,
      imgs:'',
      paymentStatus:payment,
      checkTracking: undefined,
      triggerTracking: false,
      notice: new Status(this.$swal),
      Cuoc:75000,
      CuocGoc: 75000,
      valid: false,
      disabledCount: 0,
      processedAdd:0,
      processingAdd: 0,
      ProcessAdd:0,
      Packages: null,
      selected: [],
      shipmentID: '',
      hawbID: '',
      packagesID: '',
      filter: [{code:'PhoneJP',text:'SĐT JP'},{code:'PhoneVN',text:'SĐT VN'}],
      loading: false,
      loadingTracking: false,
      loadingGop: false,
      loadingAdd: false,
      loadingCuoc: false,
      loadingStatus: false,
      loadingChangeKH: false,
      search: '',
      reqPackage: new Request('packages'),
      req: new Request('package'),
      reqTracking: new Request('tracking'),
      request: {
        ...Query
      },
      // items: [],
      boxAdd: false,
      boxTracking: false,
      boxGop: false,
      boxStatus: false,
      boxDoiCuoc: false,
      boxPhuThu: false,
      boxNote: false,
      boxChangeKH: false,
      validChangeKH: false,
      Sales: [],
      KHG: false,
      CustomerCounter: {},
      Controls:[
        {color:'blue',text:'Tracking',do:this.Tracking},
        {color:'red',text:'Xóa',do:this.Xoa},
      ],
      CapNhat:{
        ID:null,
        Tracking: '',
        ShipmentProvider:'vnpost',
      },
      PaymentStatus: {
        ID: null,
        Status: null,
      },
      loadingUpload: {},
      TrackData: [],
      TongGW: 0,
      TongSL: 0,
      DanhSachKhachHang: [],
      DanhSachSale: [],
      PhuThu:[{
        Muc: '',
        Phi: '',
      }],
      PhuThuID: undefined,
      PhuThuTemplate:{
        Muc: '',
        Phi: '',
      },
      GhiChu: '',
      GhiChuID: '',
      TongCuocSale: 0,
      TongCuocGoc: 0,
      TongLoiNhuan: 0,
      Customers:[],
      UpdateMaKH:{},
			Errors: [],
			invoice_status: [],
			boxShipping: false,
			loadingShipping: false,
			ShippingData: {},
    }
  },
  computed: {
    ...mapGetters(['authStatus','isAuthenticated','loginInfo','packageFilter']),
		LimitSale(){
			const {loginInfo} = this;
			return loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'warehouse' || loginInfo._perm === 'checker'
		},
		TotalNW(){
			return this.DanhSachMoi.reduce((a,b)=>{
				return a + b.NW
			},0);
		},
		TongTTGop(){
			let sum = 0;
			this.selected.forEach(item=>{
				sum += (item.D*item.R*item.C)/6000;
			});
			return sum.toFixed(2);
		},
		TongKLGop(){
			let sum = 0;
			this.selected.forEach(item=>{
				sum += item.W;
			});
			return sum.toFixed(2);
		},
    FilterSaleList(){
      return [{_id:null,Code:'Tất cả'}].concat(this.DanhSachSale.map(item=>{
        return {_id:item,Code:item}
      }))
    },
    FilterCustomerList(){
      return [{_id:null,Code:'Tất cả'}].concat(this.DanhSachKhachHang.map(item=>{
        return {_id:item,Code:item}
      }))
    },
    headers(){ 
      if(this.loginInfo._perm === 'warehouse'){
        return [
          // { text: '#', value:'data-table-select',width: "50px",class:"text-center grey lighten-2 black--text" },
          { text: 'Stt', value: 'index', align: 'center',width: "10%",class:"text-center grey lighten-2 black--text" },
          { text: 'Mã kiện hàng', value: 'Code', align: 'center',width: "20%",class:"text-center grey lighten-2 black--text" },
          { text: 'Hình ảnh', value: 'TaiHinh', align: 'center',width: "30%",class:"text-center grey lighten-2 black--text" },
          { text: 'Tracking', value: 'Tracking', align: 'center',width: "20%",class:"text-center grey lighten-2 black--text" },
          { text: 'Ghi chú', value: 'Note', align: 'center',width: "20%",class:"text-center grey lighten-2 black--text" },
          // { text: 'Thao tác', value: '_id', align: 'center',width: "75px",class:"text-center grey lighten-2 black--text" },
        ]
      }else if(this.loginInfo._perm === 'warehousevn'){
        return [
          { text: '#', value:'data-table-select',width: "50px",class:"text-center grey lighten-2 black--text" },
          { text: 'Stt', value: 'index', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Mã kiện hàng', value: 'Code', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Trạng thái', value: 'Status', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Sale', value: 'SaleName', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Mã KH', value: 'Customer.Username', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: 'Tên JP', value: 'Customer.NameJP', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Tên VN', value: 'Shipping.Name', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Ghi chú', value: 'Note', align: 'center',width: "200px",class:"text-center grey lighten-2 black--text" },
          { text: 'NW', value: 'NW', align: 'center',width: "75px",class:"text-center grey lighten-2 black--text" },
          { text: `GW`, value: 'GW', align: 'center',width: "75px",class:"text-center grey lighten-2 black--text" },
          { text: `SL`, value: 'CountItem', align: 'center',width: "75px",class:"text-center grey lighten-2 black--text" },
          { text: 'Tracking', value: 'Tracking', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Thao tác', value: '_id', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
        ]
      }else{
        let header = [
          { text: '#', value:'data-table-select',width: "50px",class:"text-center grey lighten-2 black--text" },
          { text: 'Stt', value: 'index', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Mã kiện hàng', value: 'Code', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Cước (Sale)', value: 'GiaSale', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: 'Trạng thái', value: 'Status', align: 'center',width: "250px",class:"text-center grey lighten-2 black--text" },
					{ text: 'Hóa đơn', value: 'SentInvoice', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: 'Sale', value: 'SaleName', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: 'Mã KH', value: 'Customer.Username', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: 'Tên JP', value: 'Customer.NameJP', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: 'Tên VN', value: 'Shipping.Name', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: 'Ghi chú', value: 'Note', align: 'center',width: "200px",class:"text-center grey lighten-2 black--text" },
          { text: 'NW', value: 'NW', align: 'center',width: "75px",class:"text-center grey lighten-2 black--text" },
          { text: `GW`, value: 'GW', align: 'center',width: "75px",class:"text-center grey lighten-2 black--text" },
          { text: 'Cước (Gốc)', value: 'GiaGoc', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: `SL`, value: 'CountItem', align: 'center',width: "75px",class:"text-center grey lighten-2 black--text" },
          { text: 'Lợi nhuận', value: 'LoiNhuan', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
					{ text: `TOM ORDER`, value: 'TO', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: 'Phí', value: 'Phi', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Phụ thu', value: 'TongPhuThu', align: 'center',width: "150px",class:"text-center grey lighten-2 black--text" },
          { text: 'Tracking', value: 'Tracking', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
          { text: 'Thao tác', value: '_id', align: 'center',width: "100px",class:"text-center grey lighten-2 black--text" },
        ]
				if(this.loginInfo._perm === 'sale' && this.loginInfo.Limited === true) header.splice(15,1);
				return header;
      }
    },
    PaymentStatusList(){
      const list = Object.keys(this.paymentStatus).map((key) => this.paymentStatus[key])
      return list
    },
    KHGFilter: function(){
      const ds = [
        {_id:null,Code:'Tất cả'}
      ]
      return ds.concat(this.packages)
    },
    lastest(){
      if(this.DanhSachMoi.length === 0) return 'Chưa có'
      const lastest = this.DanhSachMoi[this.DanhSachMoi.length-1].Code
      const strings = lastest.split('-')
      const lastestRealCode = strings[0]+'-'+strings[1]
      return lastestRealCode
    },
    DanhSachMoi: function(){
        this.ResetCounter()
        return this.items.map(
          (item, index) => {
						try {
							if (item.Customer) this.UpdateCustomerList(item.Customer.Username)
							if (item.Customer.Sale && item.Customer.Sale.Username) this.UpdateSaleList(item.Customer.Sale.Username)
							const {Customer, Sale, Status} = this.packageFilter;
							// console.log({Customer,Sale,Status});
							// if(item.Packages === undefined){
							//   item.Packages = {
							//     _id:undefined
							//   }
							// }
							if ((this.PackagesFilter === null || (item.Packages !== undefined && this.PackagesFilter === item.Packages._id)) && (Customer === null || Customer === item.Customer.Username) && (Status === null || Status === item.Status) && (Sale === null || Sale === item.Customer.Sale.Username)) {
								this.UpdateSLTong(parseInt(item.CountItem))
								this.UpdateGWTong(parseFloat(item.GW))
								// const tinhtheo = parseFloat(item.GW)
								// const SaleData = this.GetSaleData(item.Customer.Sale)

								// const GiaVon = (item.CustomShipment.Von === undefined || item.CustomShipment.Von === 0) ? parseInt(item.Customer.Sale.Shipment):parseInt(item.CustomShipment.Von)
								// const GiaGoc = GiaVon*tinhtheo+parseInt(item.TongPhuThu)
								// let Phi = (item.CustomShipment.Sale === undefined || item.CustomShipment.Sale === 0) ? parseInt(item.Customer.Shipment):parseInt(item.CustomShipment.Sale)
								// Phi = (GiaVon > Phi) ? GiaVon:Phi
								// const GiaSale = Phi*tinhtheo+parseInt(item.TongPhuThu)
								const {Von, GiaGoc, GiaSale, Phi, Min} = Cuoc({item});
								// const GiaGoc = Von*tinhtheo;
								// const GiaSale = Sale*tinhtheo;
								// const Phi = Sale;
								this.UpdateTong({GiaGoc, GiaSale});

								const r = {
									...item,
									index: index + 1,
									PackageName: `${item.Code}-${item.Count}/${item.Stt}`,
									GiaGoc: GiaGoc,
									GiaSale: GiaSale,
									LoiNhuan: GiaSale - GiaGoc,
									CuocGoc: Von,
									Min: Min,
									SaleName: (item.Customer.Sale.Username) ? (item.Customer.Sale.Username) : 'Chưa có Sale',
									Phi: Phi,
									Disabled: !!(item.Packages !== null && item.Packages !== undefined || this.shipment.Status !== 'dang_xu_ly'),
									SentInvoice: !!this.invoice_status.find(el=> el.Invoice === item.Customer.Username),
								}
								return r
							}
						}catch(e){
							console.log('Item: ', item);
							console.error(e);
							this.doBug(item);
						}
          }).filter(x => x);
      },
    uploadButtonText() {
      return this.selectedFile ? this.selectedFile.name : 'Tải ảnh'
    },
    ThongBaoDoiCuoc(){
      return `Không được bé hơn ${this.DoiCuoc(this.DoiCuocData.Min)}`
    },
  },
  watch:{
		PackageName(){
			if(this.PackageName) this.search = this.PackageName;
		},
    checkTracking(v){
      console.log(v)
    },
    triggerTracking(v){
      if(v === true){
        console.log('TRIGGER TRACKING')
        this.triggerTracking = false
      }
    }
  },
  created() {
    const self = this;
    this.items.map(item => {
      if (item.Disabled) self.disabledCount += 1
    })
  },
  methods: {
    ...validations,
    ...Query,
    ...imageProcess,
		InvoiceStatusByShipment,
    DoiCuoc:DoiTien,
		doPrint(details){
			const {_id} = details;
			this.loading = true;
			getPDF({id:_id}).then(resp=>{
				const {error} = resp;
				if(error){
					this.notice.error(error);
				}else{
					const pdfUrl = URL.createObjectURL(resp);
					printJS(pdfUrl);
				}
			}).finally(()=>{
				this.loading = false;
			})
		},
		doUpdateShippingFromAddress(){
			const {newShippingFromAddress} = this;
			if(newShippingFromAddress){
				const index = this.ShippingData['Customer']['AddressVN'].findIndex(f => f._id === newShippingFromAddress);
				if(index !== -1){
					const newData = this.ShippingData['Customer']['AddressVN'][index];
					this.ShippingData['Shipping']['Name'] = newData['Name'];
					this.ShippingData['Shipping']['Phone'] = newData['Phone'];
					this.ShippingData['Shipping']['Address'] = newData['Address'];
				}
			}
		},
		doOpenShipping(item){
			this.newShippingFromAddress = null;
			this.ShippingData = JSON.parse(JSON.stringify(item));
			this.boxShipping = true;
		},
		TaiStatusInvoice(){
			console.log('Shipment: ',this.shipment);
			return this.InvoiceStatusByShipment(this.shipment._id).then(resp=>{
				if(resp.success === true && resp.data.length > 0){
					console.log('Downloaded status invoice data !!!')
					this.invoice_status = resp.data
				}
			})
				.catch(e=>{
					this.notice.error(e)
				})
		},
		doBug(item){
			this.Errors.push({Code: item.Code,Customer:item.Customer});
		},
    doChangeKH(){
      this.loadingChangeKH = true;
      const {_id,MaKhachHang} = this.UpdateMaKH;
      this.req.update(_id,{Customer:MaKhachHang}).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success(`Đã cập nhật thông tin`);
          this.boxChangeKH = false;
          this.$emit("update:update", true);
        }else{
          const e = error || "Không thể cập nhật thông tin"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingChangeKH = false;
      })
    },
    doOpenChangeKH(item){
      this.UpdateMaKH = Object.assign({},item);
      if(item.Customer && item.Customer.Username){
        this.UpdateMaKH['MaKhachHang'] = item.Customer._id
      }
      if(this.Customers.length === 0){
        this.loadingChangeKH = true;
        this.TaiDSKH();
      }
      this.boxChangeKH = true;
    },
    UpdateFilter(){
      this.$store.dispatch('PackageFilter', this.FilterMobile);
    },
    doSaveNote(){
      if(this.loginInfo._perm !== 'checker' && this.loginInfo._perm !== 'admin' && this.loginInfo._perm !== 'manager') return;
      this.loading = true;
      this.Note(this.GhiChuID, this.GhiChu).then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success("Đã cập nhật !!!")
          this.boxStatus = false
          this.$emit("update:update", true);
        }else{
          const e = error || "Không thể cập nhật ghi chú !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loading = false
      })
    },
    updateNote(item){
      this.GhiChu = item.Note;
      this.GhiChuID = item._id;
      this.boxNote = true;
    },
    MoBoxPhuThu(item){
      this.PhuThu = item.PhuThu
      if(!item.PhuThu || item.PhuThu.length === 0){
        this.PhuThu.push(this.PhuThuTemplate)
      }
      this.boxPhuThu = true
      this.PhuThuID = item._id
    },
    UpdatePhuThu(){
      this.loadingStatus = true;
      this.req.update(this.PhuThuID,{PhuThu:this.PhuThu}).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success(`Đã cập nhật thông tin phụ thu`)
          this.$emit("update:update", true);
        }else{
          const e = error || "Không thể cập nhật thông tin phụ thu"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingStatus = true;
      })
    },
    XoaMucPhuThu(index){
      this.PhuThu.splice(index,1)
    },
    ThemMucPhuThu(){
      this.PhuThu.push(Object.assign({},this.PhuThuTemplate))
      this.$forceUpdate()
    },
    UpdateSaleList(staff){
      if((this.DanhSachSale).includes(staff) === false) this.DanhSachSale.push(staff)
    },
    UpdateCustomerList(customer){
      if((this.DanhSachKhachHang).includes(customer) === false) this.DanhSachKhachHang.push(customer)
    },
    UpdateCuoc(item){
      this.DoiCuocData = {
        Name: item.Code,
        Cuoc: item.Phi,
        CuocGoc: item.CuocGoc,
        Min: item.Min,
        ID: item._id
      }
      this.boxDoiCuoc = true
    },
    ResetCounter(){
      this.TongSL = 0; this.TongGW = 0; this.TongCuocSale = 0; this.TongCuocGoc = 0; this.TongLoiNhuan = 0;
    },
    Filter(id){
      this.PackagesFilter = id
    },
    UpdateTong({GiaGoc,GiaSale}){
      this.TongCuocSale += GiaSale;
      this.TongCuocGoc += GiaGoc;
      this.TongLoiNhuan += (GiaSale-GiaGoc);
    },
    UpdateSLTong(sl){
      this.TongSL += sl
    },
    UpdateGWTong(sl){
      this.TongGW += sl
    },
    cancelUpload(){
      this.isSelecting = {}
      this.isSelectingID = undefined
    },
    TaiHinhAnh(){
      this.loadingUpload[this.isSelectingID] = true
      this.req.Upload(this.isSelectingID,this.selectedFile,(event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      }).then(axios=>{
        const {success,error,data} = axios.data
        if(success){
          this.notice.success("Đã tải hình ảnh thành công !!!")
          // this.$emit("update:update", true);
          this.items.map((item,index)=>{
            if(item._id === this.isSelectingID){
              this.items[index].Image = data.uri
            }
          })
        }else{
          const e = error || "Không thể tải lên hình ảnh !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingUpload = {}
        this.isSelecting = {}
        this.isSelectingID = undefined
        this.selectedFile = null
        this.progress = 0
      })
    },
    onButtonClick(item) {
      this.isSelecting = true
      this.isSelectingID = item._id
      // this.$refs.uploader.$el.click()
      this.$refs.uploader.click()
    },
    handleFileUpload(e){
      const file = e.target.files && e.target.files.length ? e.target.files[0] : null
      if (file) {
        this.resizeImage({ file: file, maxSize: 1500 }).then((resizedImage) => {
          this.selectedFile = resizedImage;
          this.TaiHinhAnh()
        }).catch((err) => {
          console.log(err)
          this.notice.error("Không thể tải lên hình !!!")
        });
      }
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
      this.TaiHinhAnh()
    },
    LinkHinhAnh(item){
      if(this.isSelectingID === item._id && this.selectedFile) return URL.createObjectURL(this.selectedFile)
      const image = item.Image
      if(!image) return '/no-image.png'
      return `https://5002402-s3user.s3.cloudstorage.com.vn/tomexpress/${image}`
    },
    setImage(obj){
      this.selectedFile = obj
      this.TaiHinhAnh()
    },
    showHoaDon(image) {
      this.imgs = `https://5002402-s3user.s3.cloudstorage.com.vn/tomexpress${image}`
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide(){
      this.visible = false
    },
    UpdateNewShipping(id,Shipping){
      this.req.update(id,{Shipping}).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success(`Đã cập nhật địa chỉ !!!`)
        }else{
          const e = error || "Không thể cập nhật địa chỉ mới !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
		doPaymentUpdateList(){
			this.loadingStatus = true
			const {ListID, Status} = this.PaymentStatus
			this.uPaymentStatusList(ListID, Status).then(json=>{
				const {success,error} = json
				if(success){
					this.notice.success("Đã cập nhật !!!")
					this.boxStatus = false;
					this.ChangeStatusPackages = [];
					this.$emit("update:update", true);
				}else{
					const e = error || "Không thể cập nhật trạng thái !!!"
					this.notice.error(e)
				}
			})
			.catch(e=>{
				this.notice.error(e)
			})
			.finally(()=>{
				this.loadingStatus = false
			})
		},
    doPaymentUpdate(){
      this.loadingStatus = true
      const {ID, Status} = this.PaymentStatus
      this.uPaymentStatus(ID, Status).then(json=>{
        const {success,error} = json
        if(success){
          this.notice.success("Đã cập nhật !!!")
          this.boxStatus = false
          this.$emit("update:update", true);
        }else{
          const e = error || "Không thể cập nhật trạng thái !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingStatus = false
      })
    },
		doToggleFastStatus(){
			if(this.loginInfo._perm === 'warehouse')
			{
				this.boxStatus = false
				return
			}
			this.boxStatus = true
			this.PaymentStatus = {
				ListID: this.ChangeStatusPackages,
			}
		},
    updateStatus(item){
      if(this.loginInfo._perm === 'warehouse')
      {
        this.boxStatus = false
        return
      }
      this.boxStatus = true
      this.PaymentStatus = {
        ID: item._id,
        Status: item.Status,
        ImageTT: item.ImageTT
      }
    },
    TitleDoiCuoc(item){
      return `Đổi cước: ${item.Code} - Cước cũ: ${this.DoiCuoc(item.Phi)}`
    },
    Track(Tracking){
      const Track = Tracking.trim()
      if(Track){
        this.loadingTracking = true
        this.reqTracking.fetch(Track).then(resp=>{
          return resp.json()
        })
        .then(json=>{
          if(json.success === true){
            this.TrackData = json.data
          }else{
            const e = json.error || "Không thể kiểm tra thông tin tracking của bạn !!!"
            this.notice.error(e)
          }
        })
        .catch(e=>{
          this.notice.error(e)
        })
        .finally(()=>{
          this.loadingTracking = false
        })
      }else{
        this.notice.error("Vui lòng nhập thông tin tracking !!!")
      }
    },
    CapNhatCuoc(){
      const Cuoc = parseInt(this.DoiCuocData.Cuoc)
      const CuocGoc = parseInt(this.DoiCuocData.CuocGoc)
      const min = (this.loginInfo._perm === 'admin') ? 0:CuocGoc
      if(Cuoc < min) return this.notice.error(`Cước không được bé hơn ${this.DoiCuoc(min)}`)
      const CustomShipment = {
        Sale: Cuoc,
        Von: CuocGoc,
      }
      this.loadingCuoc = true
      this.request.cShipment(this.DoiCuocData.ID,CustomShipment).then(json=>{
        if(json.success === true){
          this.notice.success("Đã cập nhật !!!")
          this.$emit("update:update", true);
        }else{
          const msg = json.error || "Không thể cập nhật cước cho kiện hàng !!!"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingCuoc = false
      })
    },
    GetSaleData(id){
      let Data = {}
      this.sales.map(item=>{
        if(item._id === id) Data=Object.create(item)
      })
      return Data
    },
    TimTheoKHG(searchID){
      const url = (searchID === null) ? this.shipment._id:`byPackages/${searchID}`
      this.loading = true
      this.req.fetch(url).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.items = json.data
        }else{
          const msg = json.error || "Lỗi kết nối máy chủ !!!"
          this.notice.error(msg)
        }
      })
      .catch(err=>{
        this.notice.error(err)
      })
      .finally(()=>{
        this.loading = false
      })
    },
    CustomControls(item){
      let Controls = [];
			if(this.shipment.Status === 'dang_xu_ly' && !item.Disabled && this.loginInfo._perm !== 'sale'){
				Controls.push({color:'red',text:'Xóa',do:this.Xoa});
			}
      if(item.CountItem > 0)
      {
        Controls.splice(1,1)
      }
      if(item.Disabled && this.shipment.Status === 'dang_xu_ly' && this.loginInfo._perm.toString() !== 'sale'){
        Controls.splice(1,0,{color:'blue',text:'Gỡ kiện',do:this.RemoveIt})
      }
			if(this.loginInfo._perm.toString() !== 'sale') Controls.push({color:'blue',text:'Tracking',do:this.Tracking});
      return Controls
    },
    selectAllToggle(props) {
       if(this.selected.length != this.items.length - this.disabledCount) {
         this.selected = [];
         const self = this;
         props.items.forEach(item => {
           if(!item.Disabled) {
             self.selected.push(item);
           } 
         });
       } else this.selected = [];
    },
    GopKienHang(data){
      this.loadingGop = true
      let ItemID = []
      this.selected.forEach(item=>{
        ItemID.push(item._id)
      })
      const request = {
        ...data,
        Items: ItemID
      }
      this.request.merge(this.shipment._id,request).then(json=>{
        if(json.success === true){
          this.selected = []
          this.disabledCount = 0
          this.boxGop = false
          this.notice.success("Đã cập nhật !!!")
          this.$emit("update:update", true);
        }else{
          const msg = json.error || "Không thể gộp danh sách kiện hàng !!!"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingGop = false
      })
    },
		PickupPackage(){
			this.loadingTracking = true
			this.request.uPaymentStatus(this.CapNhat.ID,'da_nhan_tai_kho').then(json=>{
				if(json.success === true){
					this.boxTracking = false
					this.notice.success("Đã cập nhật !!!")
					this.$emit("update:update", true);
				}else{
					const msg = json.error || "Không thể tìm item !!!"
					this.notice.error(msg)
				}
			})
				.catch(e=>{
					this.notice.error(e)
				})
				.finally(()=>{
					this.loadingTracking = false
				})
		},
    CapNhatTrack(){
      this.loadingTracking = true
      this.request.uTrack(this.CapNhat.ID,{Tracking:this.CapNhat.Tracking,ShipmentProvider:this.CapNhat.ShipmentProvider}).then(json=>{
        if(json.success === true){
          this.boxTracking = false
          this.notice.success("Đã cập nhật !!!")
          this.$emit("update:update", true);
        }else{
          const msg = json.error || "Không thể tìm item !!!"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingTracking = false
      })
    },
    Tracking(data){
      this.CapNhat = {ID:data._id, Tracking: data.Tracking, ShipmentProvider: data.ShipmentProvider};
			console.log(this.CapNhat);
      this.boxTracking = true
    }, 
    Status: function(Status){
      return (Status) ? {color:'red',text:'Khóa'}:{color:'green',text:'Mở'}
    },
    Link(Code){
      return `/detail/${Code}`
    },
    RemoveIt(item){
      this.loading = true
      this.request.rPackages(item._id).then(json=>{
        if(json.success === true){
          this.notice.success("Đã gỡ kiện !!!");
          this.$emit("update:update", true);
        }else{
          const msg = json.error || "Không thể gỡ kiện !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
      .finally(()=>{
        this.loading = false
      })
    },
    async Them(data) {
      this.loadingAdd = true
      this.processedAdd = 0
      data.map((item,index)=>{
        const requestData = item
        this.request.create(requestData).then()
        .catch(err=>{
          this.notice.error(err);
        })
        .finally(()=>{
          this.processedAdd += 1
          this.ProcessAdd = this.processedAdd*100/data.length
          if(index === (data.length -1))
          {
            this.boxAdd = false
            this.loadingAdd = false
            this.$emit("update:update", true);
          }
        })
      })
    },
    Xoa(item){
      this.req.delete(item._id).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.notice.success("Đã xóa dữ liệu !!!");
          this.$emit("update:update", true);
        }else{
          const msg = json.error || "Không thể xóa dữ liệu !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
    },
    TaiDSSale(){
      this.req.fetch('list-sale').then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.Sales = json.data
        }else{
          this.notice.error("Không thể tải danh sách Sale")
        }
      })
    },
    TaiDSKH(){
      this.req.fetch('list-customer').then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.Customers = json.data
        }else{
          this.notice.error("Không thể tải danh sách khách hàng")
        }
      }).finally(()=>{
        this.loadingChangeKH = false;
      })
    },
  },
  mounted(){
    // this.LayDuLieu()
    // this.TaiDSSale()
		console.log('Shipment: ',this.shipment);
		console.log(this.PackageName);
		this.search = this.PackageName;
		this.TaiStatusInvoice();
  },
  props: ["shipment","Load","packages","update","items","sales", "PackageName"]
}
</script>
<style scoped>
.hide {
    visibility: hidden !important;
}
</style>