<template>
  <div>
    <v-container >
      <v-card>
        <v-tabs
          v-model="tab"
          background-color="#0f2e8c"
          center-active
          dark
        >
					<v-tab href="#shipment" disabled>{{!shipment.Code ? '...':shipment.Code}}</v-tab>
          <v-tab href="#hawb" v-if="shipment.Type === 'air' && (loginInfo._perm === 'admin' || loginInfo._perm === 'checker')" >HAWB</v-tab>
          <v-tab href="#packages" v-if="loginInfo._perm === 'admin' || loginInfo._perm === 'checker'">KIỆN HÀNG GỘP</v-tab>
          <v-tab href="#package">DANH SÁCH KIỆN HÀNG</v-tab>
          <v-tab href="#detail" disabled>QUẢN LÍ KIỆN HÀNG</v-tab>
        <v-overlay
          absolute
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            v-if="loading"
          />
        </v-overlay>
        </v-tabs>
      </v-card>
      <v-tabs-items v-model="tab"  v-if="loading === false">
				<v-tab-item
					value="shipment"
				/>
        <v-tab-item
          value="hawb"
        >
          <br>
          <v-card flat>
            <TabHawb :shipment="shipment" :update.sync="update" :items="hawbList" :Load="LoadPackages"  />
          </v-card>
        </v-tab-item>
        <v-tab-item
          value="packages"
        >
          <br>
          <v-card flat>
            <TabPackages ref="tPackages" :update.sync="update" :shipment="shipment" :Load="LoadPackage" :items="packagesList" :hawb="hawbList"  />
          </v-card>
        </v-tab-item>
        <v-tab-item
          value="package"
        >
          <br>
          <v-card flat>
            <TabPackage ref="tPackage" :update.sync="update" :shipment="shipment" :Load="LoadDetail" :items="packageList" :packages="packagesList" :sales="salesList" :PackageName="PackageName" />
          </v-card>
        </v-tab-item>
        <v-tab-item
          value="detail"
        >
          <br>
          <v-card flat>
            <DetailPackage :update.sync="update" :package.sync="packageDetail" :items.sync="packageItems" :shipment="shipment" v-if="packageDetail._id !== undefined" />
          </v-card>
          <v-overlay
            absolute
            :value="loadDetail"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="loadDetail"
            />
          </v-overlay>
        </v-tab-item>
    </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Query from '@/plugins/query/global'
import { Request } from '@/plugins/helper/fetch'
import {shipment} from '@/plugins/helper/status'
import { Status } from '@/plugins/helper/dstatus'
import TabHawb from '@/views/Hawb.vue'
import TabPackage from '@/views/Package.vue'
import DetailPackage from '@/views/Detail.vue'
import TabPackages from '@/views/Packages.vue'
export default {
  name: 'shipment',
  components: {
    TabHawb,
    TabPackage,
    DetailPackage,
    TabPackages
  },
  data () {
    return {
			PackageFilter: {},
      notice: new Status(this.$swal),
      shipmentID: undefined,
      status: {
        ...shipment
      },
      update: false,
      filter: [{code:'PhoneJP',text:'SĐT JP'},{code:'PhoneVN',text:'SĐT VN'}],
      loading: true,
      loadDetail: false,
      search: '',
      tab: '',
      req: new Request('shipment'),
      reqp: new Request('package'),
      reqpg: new Request('packages'),
      reqh: new Request('hawb'),
      shipment: false,
      boxAdd: false,
      packageDetail: {},
      packageItems: [],
      packageUpdate:false,
      packagesUpdate: false,
      packageList: [],
      packagesList: [],
      hawbList: [],
      salesList: [],
			PackageName: null,
    }
  },
  computed: {
    ...mapGetters(['authStatus','isAuthenticated','loginInfo']),
		packageFilter() {
			return {...this.$store.state.packageFilter}
		},
    DanhSachMoi: function(){
        return this.items.map(
          (items, index) => ({
            ...items,
            index: index + 1
          }))
      }
  },
  watch:{
		packageFilter(v){
			this.PackageFilter = Object.assign({},v);
		},
    tab: function(v){
      if(v === 'package'){
        this.packageDetail = {}
      }
    },
    update: function(v){
      if(v === true){
        this.LayDuLieu(this.tab)
        this.update = false
      }
    }
  },
  methods: {
    ...Query,
		UpdateFilter(){
			this.$store.dispatch('PackageFilter', this.PackageFilter);
		},
    LayDuLieu(tab) {
      this.loading = true
      Promise.all([
        this.TaiShipment(),
        this.TaiPackage(),
        this.TaiPackages(),
        this.TaiHawb(),
        this.TaiSales(),
      ]).finally(()=>{
        this.tab = (tab === undefined || tab === null || tab === '') ? 'package':tab
        this.loading = false
				const {Package, PackageName} = this.$route.query;
				if(Package && PackageName){
					this.$router.replace({'query': {}});
					this.LoadDetail(Package, PackageName);
				}
        console.log('DONE !!!')
      })
    },
    TaiShipment(){
      return this.Shipment(this.shipmentID).then(resp=>{
        if(resp.success === true){
          console.log('Downloaded shipment data !!!')
          this.shipment = resp.data
        }else{
          const msg = resp.error || "Không thể tải thông tin Shipment"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
    TaiPackage(){
      return this.Package(this.shipmentID).then(resp=>{
        if(resp.success === true){
          console.log('Downloaded package data !!!')
          this.packageList = resp.data
        }else{
          const msg = resp.error || "Không thể tải thông tin kiện hàng"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
    TaiPackages(){
      return this.Packages(this.shipmentID).then(resp=>{
        if(resp.success === true){
          console.log('Downloaded packages data !!!')
          this.packagesList = resp.data
        }else{
          const msg = resp.error || "Không thể tải thông tin kiện hàng gộp"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
    TaiHawb(){
      return this.Hawb(this.shipmentID).then(resp=>{
        if(resp.success === true){
          console.log('Downloaded HAWB data !!!')
          this.hawbList = resp.data
        }else{
          const msg = resp.error || "Không thể tải thông tin HAWB"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
    TaiSales(){
      return this.Sales().then(resp=>{
        if(resp.success === true){
          console.log('Downloaded Sales data !!!')
          this.salesList = resp.data
        }else{
          const msg = resp.error || "Không thể tải thông tin Sales"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
    },
    LoadPackages(id){
      this.tab = 'packages'
      setTimeout(() => {
        this.$refs.tPackages.Filter(id) 
      }, 100);
    },
    async LoadPackage(id){
      await this.$refs.tPackage.Filter(id)
      this.tab = 'package'
    },
    LoadDetail(id,name){
      this.loadDetail = true
      this.tab = 'detail'
      this.packageItems = []
      this.packageDetail = {}
      this.reqp.byID(id).then(async resp=>{
        const json = await resp.json()
        if(json.success === true && json.data){
          const data = {
            ...json.data,
            PackageName: name
          }
          let newPackageItems = []
          let newPackageItemNums = []
          json.data.Items.map((item,index)=>{
            newPackageItems.push(item._id)
            newPackageItemNums.push(json.data.SL[index])
          })
          this.packageItems = {Item:newPackageItems,SL:newPackageItemNums}
          this.packageDetail = data
        }else{
          const msg = json.error || "Không thể tải thông tin kiện hàng"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadDetail = false
      })

    },
    TimKiem() {

    },
    async TaiDanhSachKHG(){
      try{
        const resp = await this.reqpg.fetch(this.$route.params.shipmentID)
        const json = await resp.json()
        if(json.success === true){
          return json.data
        }else{
          this.notice.error("Không thể tải danh sách kiện hàng gộp")
        }
      }catch(err){
        this.notice.error(err)
        return false
      }
    },
    async TaiDanhSachHawb(){
      await this.reqh.fetch(this.$route.params.shipmentID).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.HAWB = json.data
        }else{
          this.notice.error("Không thể tải danh sách hawb")
        }
      })
    }
  },
  created(){
    this.shipmentID = this.$route.params.shipmentID
    this.LayDuLieu()
  },
	mounted(){
		this.PackageFilter = Object.assign({},this.packageFilter);
		if(this.$route.query.Package){
			this.PackageName = this.$route.query.Package;
			console.log(this.PackageName);
		}
		if(this.$route.query.Customer){
			this.PackageFilter.Customer = this.$route.query.Customer;
		}else{
			this.PackageFilter.Customer = null;
		}
		this.UpdateFilter();
	}
}
</script>
