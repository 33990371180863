<template>
  <div class="team">
    <v-container >
      <v-layout row wrap>
         <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách kiện hàng gộp</h3>
              </v-toolbar>
              <v-card-title>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    lg="2"
                    xl="2"
                    v-if="hawb.length > 0"
                  >
                    <v-select
                        v-model="Hawb"
                        :items="KHGFilter"
                        item-value="_id"
                        item-text="Code"
                        label="Lọc theo mã hawb"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="2"
                    xl="2"
                  >
                    <v-btn
                      color="blue"
                      dark
                      :disabled="selected.length === 0"
                      @click="boxGop = true"
                    >
                      Gộp thành HAWB ({{ TongKL }} Kg | TT: {{ TongTT }}  | {{selected.length}} Kiện)
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Tìm kiếm"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                v-model="selected"
                :loading="loading"
                :headers="headers"
                :items="DanhSachMoi"
                :search="search"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                :items-per-page="50"
                item-key="_id"
                show-select
                height="750px"
                fixed-header
                @toggle-select-all="selectAllToggle"
              >
                <template v-slot:[`body.append`]>
                    <tr>
                      <td/>
                      <td/>
                      <td class="text-h5 text-center">
                        {{DanhSachMoi.length}}
                      </td>
                      <td class="text-h5 text-center">
                        {{ (TongGW).toFixed(2) }}
                      </td>
                      <td class="text-h5 text-center">
                        {{ TongSL }}
                      </td>
                      <td/>
                      <td/>
                      <td/>
                      <td class="text-h5 text-center">
                        {{ (TongVol).toFixed(2) }}
                      </td>
                      <td/>
                    </tr>
                </template>
                <template v-slot:[`body.prepend`]>
                  <tr>
                    <td/>
                    <td/>
                    <td class="text-h5 text-center">
                      {{DanhSachMoi.length}}
                    </td>
                    <td class="text-h5 text-center">
                      {{ (TongGW).toFixed(2) }}
                    </td>
                    <td class="text-h5 text-center">
                      {{ TongSL }}
                    </td>
                    <td/>
                    <td/>
                    <td/>
                    <td class="text-h5 text-center">
                      {{ (TongVol).toFixed(2) }}
                    </td>
                    <td/>
                  </tr>
                </template>
                <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                  <v-simple-checkbox
                    :ripple="false"
                    :value="isSelected"
                    @input="select($event)"
                    v-if="!item.Disabled"
                  />
                </template>
                <template v-slot:[`header.data-table-select`]>
									<v-checkbox
										v-model="selectAll"
										@change="selectAllData"
										v-if="CanSelect.length > 0"
									/>
								</template>
                <template v-slot:[`item.Code`]="{ item }">
                    <v-btn @click="Load(item._id)" text color="primary">
                        {{ item.Code }}
                    </v-btn>
                </template>
                <template v-slot:[`item.Locked`]="{ item }">
                    <v-btn text :color="Status(item.Locked).color">{{ Status(item.Locked).text }}</v-btn>
                </template>
                <template v-slot:[`item.totalWeight`]="{ item }">
                    {{ item.totalWeight }}
                </template>
                <template v-slot:[`item.createAt`]="{ item }">
                    {{ moment(item.createAt).tz("Asia/Bangkok").format("DD-MM-YYYY HH:mm") }}
                </template>
                <template v-slot:[`item._id`]="{ item }">
                  <template v-if="shipment.Status === 'dang_xu_ly'">
                    <Manage :item="item" :controls="CustomControls(item)" />
                  </template>
                </template>
              </v-data-table>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách kiện hàng gộp</h3>
              </v-toolbar>
              <v-overlay
                absolute
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loading"
                />
              </v-overlay>
            </v-card>
          </template>
         </v-flex>
      </v-layout>
    </v-container>
    <BoxGop :enable.sync="boxGop" :list="hawb" :tong="TongKL" :tongtt="TongTT" :kien="selected.length" :loading="loadingGop" :submitForm="GopHawb" />
    <v-dialog
      v-model="boxThongTin"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            <v-btn
              icon
              dark
              @click="boxThongTin = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Thông tin kiện hàng gộp</v-toolbar-title>
          </v-toolbar>  
          <v-card-title></v-card-title>
          <v-card-text>
            <v-form v-model="validFormTT">
              <v-row>
                <v-col
                  cols=4
                >
                  <v-text-field
                    v-model="TTKienHangGop.D"
                    label="Dài (cm)"
                    :rules="[required('Dài')]"
                    @change="UpdateVolumn"
                  />
                </v-col>
                <v-col
                  cols=4
                >
                  <v-text-field
                    v-model="TTKienHangGop.R"
                    label="Rộng (cm)"
                    :rules="[required('Rộng')]"
                    @change="UpdateVolumn"
                  />
                </v-col>
                <v-col
                  cols=4
                >
                  <v-text-field
                    v-model="TTKienHangGop.C"
                    label="Cao (cm)"
                    :rules="[required('Cao')]"
                    @change="UpdateVolumn"
                  />
                </v-col>
                <v-col
                  cols=4
                >
                  <v-text-field
                    v-model="TTKienHangGop.Volumn"
                    label="Volumn (m3)"
                    disabled
                  />
                </v-col>
                <v-spacer />
                <v-col
                  cols=4
                >
                  <v-btn :disabled="!validFormTT" color="green" dark @click="doUpdateTT">
                    Lưu
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <Loading :status="loadingTT" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Request } from '@/plugins/helper/fetch'
import { Status } from '@/plugins/helper/dstatus'
import { pad } from '@/plugins/helper/string'
import { CountTotal } from '@/plugins/helpers.js'
import Manage from '@/components/base/thaotac.vue'
import BoxGop from '@/components/packages/gop.vue'
import Loading from '@/components/base/Loading.vue'
import validations from '@/plugins/helper/validations'
export default {
  name: 'packages',
  components: {
    Manage,
    BoxGop,
    Loading,
  },
  data () {
    return {
			selectAll: false,
      notice: new Status(this.$swal),
      Hawb:null,
      selected: [],
      dialog:false,
      loadingTT: false,
      disabledCount: 0,
      shipmentID: '',
      validFormTT: false,
      filter: [{code:'PhoneJP',text:'SĐT JP'},{code:'PhoneVN',text:'SĐT VN'}],
      loading: false,
      loadingGop: false,
      search: '',
      req: new Request('packages'),
      headers: [
        { value:'data-table-select', width:"50px",class:"grey lighten-2 black--text" },
        { text: '#', value: 'index', align: 'center',width: "75px",class:"grey lighten-2 black--text" },
        { text: 'Mã kiện', value: 'Code', align: 'center',width: "150px",class:"grey lighten-2 black--text" },
        { text: 'Khối lượng', value: 'totalWeight', align: 'center',width: "75px",class:"grey lighten-2 black--text" },
        { text: 'Số lượng', value: 'Count', align: 'center',width: "75px",class:"grey lighten-2 black--text" },
        { text: 'Dài', value: 'D', align: 'center',width: "75px",class:"grey lighten-2 black--text" },
        { text: 'Rộng', value: 'R', align: 'center',width: "75px",class:"grey lighten-2 black--text" },
        { text: 'Cao', value: 'C', align: 'center',width: "75px",class:"grey lighten-2 black--text" },
        { text: 'Volumn', value: 'Volumn', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Thao tác', value: '_id', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
      ],
      SelectedPackages: false,
      TTKienHangGop:{
        D: 0,
        R: 0,
        C: 0,
        Volumn: 0
      },
      boxAdd: false,
      boxGop: false,
      boxThongTin: false,
      TongGW: 0,
      TongVol: 0,
      TongSL: 0,
    }
  },
  computed: {
		CanSelect(){
			return this.DanhSachMoi.filter(v=>!v.Disabled);
		},
    TongKL(){
      return CountTotal(this.selected,'totalWeight')
    },
    TongTT(){
      return CountTotal(this.selected,'Volumn')
    },
    KHGFilter: function(){
      const ds = [
        {_id:null,Code:'Tất cả'}
      ]
      return ds.concat(this.hawb)
    },
    DanhSachMoi: function(){
        this.ResetCounter()
        const data = this.items.map(
          (item, index) => {
            if(item.Hawb  || this.Hawb === null){
              if(this.Hawb === null || item.Hawb._id === this.Hawb){
                const totalWeight = CountTotal(item.PackageData)
                this.UpdateSLTong(parseInt(item.Count))
                this.UpdateGWTong(parseFloat(totalWeight))
                this.UpdateVolTong(parseFloat(item.Volumn))
                return  {
                  ...item,
                  index: index + 1,
                  MaHawb: pad(index+1,2),
                  totalWeight: totalWeight,
                  Disabled: !!(item.Hawb !== null && item.Hawb !== undefined || (item.Count === 0 || CountTotal(item.PackageData) === 0) || this.shipment.Status === 'khoa' || this.shipment.Status === 'dang_kiem_tra')
                }
              }
            }
          })
        return data.filter(x => x);
      },
  },
  created() {
    const self = this;
    this.items.map(item => {
      if (item.Disabled) self.disabledCount += 1
    })
  },
  methods: {
    ...validations,
    // TongKL: function(){
    //   return CountTotal(this.selected,'totalWeight')
    // },
    ResetCounter(){
      this.TongVol = 0; this.TongGW = 0; this.TongSL = 0
    },
    Filter(id){
      this.Hawb = id
    },
    UpdateGWTong(sl){
      this.TongGW += sl
    },
    UpdateSLTong(sl){
      this.TongSL += sl
    },
    UpdateVolTong(sl){
      this.TongVol += sl
    },
    doUpdateTT(){
      this.loadingTT = true
      this.req.update(this.SelectedPackages,this.TTKienHangGop).then(resp=>{
        return resp.json()
      }).then(json=>{
        if(json.success === true){
          this.notice.success("Đã cập nhật thông tin kiện hàng gộp");
          this.$emit("update:update", true);
        }else{
          const msg = json.error || "Không thể cập nhật thông tin KHG !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
      .finally(()=>{
        this.loadingTT = false
      })
    },
    UpdateVolumn(){
      const {D,R,C} = this.TTKienHangGop
      // const KQ = parseFloat(parseInt(D)*parseInt(R)*parseInt(C)/1000000).toFixed(2)
      const KQ = parseFloat(parseInt(D)*parseInt(R)*parseInt(C)/6000).toFixed(2)
      this.TTKienHangGop.Volumn = isNaN(KQ) ? 0:KQ;
    },
    GopHawb(data){
      const TongKL = CountTotal(this.selected,'totalWeight')
      if(data.MaxWeight < TongKL) return this.notice.error(`Khối lượng tối đa không được nhỏ hơn ${TongKL}`)
      let ItemID = []
      this.selected.forEach(item=>{
        ItemID.push(item._id)
      })
      const request = {
        ...data,
        Weight: TongKL,
        Items: ItemID,
        Shipment:this.shipment._id
      }
      this.req.create(request).then(resp=>{
        return resp.json()
      }).then(json=>{
        if(json.success === true){
          this.selected = []
          this.disabledCount = 0
          this.boxGop = false
          this.notice.success("Đã cập nhật !!!")
          this.$emit("update:update", true);
        }else{
          const msg = json.error || "Không thể gộp danh sách kiện hàng !!!"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingGop = false
      })
    },
		selectAllData() {
			if(!this.selectAll){
				this.selected = [];
				return;
			}
			this.selected = [];
			const self = this;
			this.DanhSachMoi.forEach(item => {
				if(!item.Disabled) {
					self.selected.push(item);
				}
			});
		},
    selectAllToggle(props) {
       if(this.selected.length != this.items.length - this.disabledCount) {
         this.selected = [];
         const self = this;
         props.items.forEach(item => {
           if(!item.Disabled) {
             self.selected.push(item);
           } 
         });
       } else this.selected = [];
     },
    ThongTin(item){
      this.SelectedPackages = item._id
      const {D,R,C,Volumn} = item
      this.TTKienHangGop = {D,R,C,Volumn}
      this.boxThongTin = true
    },
    CustomControls(item){
      let Controls = [{color:'red',text:'Xóa',do:this.Xoa},{color:'green',text:'Thông tin',do:this.ThongTin}]
      if(item.Count > 0)
      {
        Controls.splice(0,1)
      }
      if(item.Disabled){
        if(item.Count > 0){
          Controls.splice(1,0,{color:'blue',text:'Gỡ khỏi Hawb',do:this.RemoveIt})
        }
      }
      return Controls
    },
    Status: function(Status){
      return (Status) ? {color:'red',text:'Khóa'}:{color:'green',text:'Mở'}
    },
    RemoveIt(item){
      this.loading = true
      this.req.Go(item._id).then(resp=>{
        return resp.json()
      }).then(json=>{
        if(json.success === true){
          this.notice.success("Đã gỡ kiện gộp ra khỏi hawb !!!");
          this.$emit("update:update", true);
        }else{
          const msg = json.error || "Không thể gỡ kiện !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
      .finally(()=>{
        this.loading = false
      })
    },
    Xoa(item){
      this.req.delete(item._id).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.notice.success("Đã xóa dữ liệu !!!");
          this.$emit("update:update", true);
        }else{
          const msg = json.error || "Không thể xóa dữ liệu !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
    }
  },
  mounted(){
    // this.LayDuLieu()
  },
  props:["items","shipment","Load","update","hawb"]
}
</script>
