<template>
  <div class="team">
    <v-container >
      <v-layout row wrap>
         <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách hawb {{ shipment.Code }}</h3>
              </v-toolbar>
              <v-card-title>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Tìm kiếm"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="DanhSachMoi"
                :search="search"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                :items-per-page="20"
              >
								<template v-slot:[`item.Documents`]="{ item }">
									<v-list flat>
										<v-subheader>Tài liệu</v-subheader>
										<v-list-item-group
											color="primary"
										>
											<v-list-item
												v-for="(stt, i) in ListDocumentStatus"
												:key="i"
											>
												<v-list-item-icon>
													<v-icon
														:color="item['DocumentUploaded'][stt.code] ? 'green':'red'"
													>
														{{item['DocumentUploaded'][stt.code] ? 'mdi-check-circle':'mdi-cancel'}}
													</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-menu offset-y>
														<template v-slot:activator="{ on, attrs }">
															<v-list-item-title
																v-text="stt.text"
																v-bind="attrs"
																v-on="on"
															>

															</v-list-item-title>
														</template>
														<v-list>
															<v-list-item v-if="!item['DocumentUploaded'][stt.code]">
																<v-list-item-title>
																	<v-btn
																		dark
																		color="green"
																		@click="openUpload(item,stt.code)"
																	>
																		Tải lên
																	</v-btn>
																</v-list-item-title>
															</v-list-item>
															<v-list-item v-if="loginInfo._perm === 'admin' && item['DocumentUploaded'][stt.code]">
																<v-list-item-title>
																	<v-btn
																		dark
																		color="blue"
																		@click="doLoadDocument(item._id, stt.code)"
																	>
																		Xem tài liệu
																	</v-btn>
																</v-list-item-title>
															</v-list-item>
															<v-list-item v-if="loginInfo._perm === 'admin' && item['DocumentUploaded'][stt.code]">
																<v-list-item-title>
																	<v-btn
																		dark
																		color="orange"
																		v-if="item['DocumentUploaded'][stt.code]"
																		@click="doRemoveDocument(item._id, stt.code)"
																	>
																		Xóa tài liệu
																	</v-btn>
																</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
												</v-list-item-content>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</template>
                <template v-slot:[`item.Code`]="{ item }">
                    <v-btn @click="Load(item._id)" text :color="item.Locked ? 'orange':'primary'">
                        {{ item.Code }}
                    </v-btn>
                </template>
                <template v-slot:[`item.Locked`]="{ item }">
                    <v-btn text :color="Status(item.Locked).color">{{ Status(item.Locked).text }}</v-btn>
                </template>
                <template v-slot:[`item.MaxWeight`]="{ item }">
                    {{ item.Weight }} / {{item.MaxWeight}}
                </template>
                <template v-slot:[`item.totalItems`]="{ item }">
                    {{ item.totalItems }}
                </template>
                <template v-slot:[`item._id`]="{ item }">
                  <Manage :item="item" :controls="CustomControls(item)" />
                </template>
              </v-data-table>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách hawb {{ shipment.Code }}</h3>
              </v-toolbar>
            </v-card>
          </template>
         </v-flex>
      </v-layout>
    </v-container>
		<v-dialog
			max-width="600px"
			persistent
			v-model="boxDocument"
		>
			<v-card>
				<v-card-title>Tải lên tài liệu - Hawb: {{DocumentHawbCode}}</v-card-title>
				<v-card-text>
					<v-file-input
						v-model="DocumentFile"
						show-size
						truncate-length="15"
						label="Tải lên"
					/>
					<br />
					<v-select
						v-model="DocumentType"
						:items="ListDocumentStatus"
						item-value="code"
						item-text="text"
						label="Loại tài liệu"
					/>
					<br />
				</v-card-text>
				<v-card-actions>
					<v-btn
						dark
						@click="boxDocument = false"
					>Close</v-btn>
					<v-btn dark color="green" :disabled="UploadState || DocumentFile === null" :loading="UploadState" @click="doUpload">Tải lên</v-btn>
				</v-card-actions>
			</v-card>
			<Loading :status="UploadState"/>
		</v-dialog>

	</div>
</template>

<script>
import Query from '@/plugins/query/global'
import { Request } from '@/plugins/helper/fetch'
import { Status } from '@/plugins/helper/dstatus'
import { CountTotal } from '@/plugins/helpers.js'
import Manage from '@/components/base/thaotac.vue'
import Loading from '../components/base/Loading.vue'

import { documentHawb } from '@/plugins/helper/status'
import {mapGetters} from "vuex";
import {LoadDocumentHawb} from "../plugins/query/global";
export default {
  name: 'hawb',
  components: {
    Manage,
		Loading,
  },
  data () {
    return {
			DocumentHawbCode: null,
			UploadProgress: 0,
			DocumentHawb: null,
			DocumentType: null,
			boxDocument: false,
			UploadState: false,
			DocumentFile: null,
      notice: new Status(this.$swal),
      shipmentID: '',
      filter: [{code:'PhoneJP',text:'SĐT JP'},{code:'PhoneVN',text:'SĐT VN'}],
      loading: false,
      search: '',
      reqShipment: new Request('shipment'),
      req: new Request('hawb'),
      headers: [
            { text: '#', value: 'index', align: 'center',width: "5%",class:"grey lighten-2 black--text" },
            { text: 'Mã Hawb', value: 'Code', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
            { text: 'Khối lượng / Tối đa', value: 'MaxWeight', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
            { text: 'Số lượng', value: 'Count', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
						{ text: 'Tài liệu', value: 'Documents', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
            { text: 'Thao tác', value: '_id', align: 'center',width: "10%",class:"grey lighten-2 black--text" },
      ],
			DocumentStatus: documentHawb,
      boxAdd: false,
    }
  },
  computed: {
		...mapGetters(['authStatus','isAuthenticated','loginInfo']),
		ListDocumentStatus: () =>{
			return Object.keys(documentHawb).map(key => ({
				...documentHawb[key]
			}))
		},
    DanhSachMoi: function(){
        return this.items.map(
          (items, index) => ({
            ...items,
            index: index + 1,
          }))
      },
    ShipmentID: function(){
      if(this.shipment.Code === ''){
        return ''
      }
      return ` - Shipment: ${this.shipment.Code}`
    }
  },
  methods: {
		...Query,
		async doRemoveDocument(id, type){
			const confirm = await this.notice.confirm('Bạn có xác nhận xóa ? Hành động không thể phục hồi !!!');
			if(confirm){
				this.req.delete('doc/'+id+'?type='+type).then(async resp=>{
					const json = await resp.json()
					if(json.success === true){
						this.notice.success("Đã xóa tài liệu !!!");
						this.$emit("update:update", true);
					}else{
						const msg = json.error || "Không thể xóa tài liệu !!!"
						this.notice.error(msg);
					}
				})
					.catch(err=>{
						this.notice.error(err);
					})
			}
		},
		doLoadDocument(id,type){
			this.LoadDocumentHawb(id+'?type='+type).then(resp=>{
				const {data} = resp;
				if(data){
					window.open('https://5002402-s3user.s3.cloudstorage.com.vn/tomexpress/'+data);
				}
			})
		},
		doUpload() {
			this.UploadState = true;
			this.req.Upload(this.DocumentHawb+'?type='+this.DocumentType, this.DocumentFile, (event) => {
				this.UploadProgress = Math.round((100 * event.loaded) / event.total);
			},).then(axios => {
				const {success, error} = axios.data
				if (success) {
					this.notice.success("Đã tải tài liệu thành công !!!");
					this.boxDocument = false;
					this.$emit("update:update", true);
				} else {
					const e = error || "Không thể tải lên tài liệu !!!"
					this.notice.error(e)
				}
			})
				.catch(e => {
					this.notice.error(e)
				})
				.finally(() => {
					this.UploadState = false;
				})
		},
		openUpload(item, type){
			this.DocumentHawbCode = item.Code;
			this.UploadProgress = 0;
			this.DocumentFile = null;
			this.DocumentType = type;
			this.DocumentHawb = item._id;
			this.boxDocument = true;
		},
    CustomControls(item){
      let Controls = [{color:'red',text:'Xóa',do:this.Xoa}]
      if(item.Count > 0 || item.Shipment.Status !== 'dang_xu_ly')
      {
        Controls.splice(0,1)
      }
      Controls.push({color:'primary',text:'Invoice',do:this.GetInvoice})
      return Controls
    },
    GetInvoice(item){
      this.loading = true
      this.req.fetch('invoice/'+item._id).then(resp=>{
        return resp.json()
      })
      .then(json=>{
        if(json.success === true && json.data)
        {
          const STORAGE = 'https://5002402-s3user.s3.cloudstorage.com.vn/tomexpress/'
          let a = document.createElement("a");
          a.href = STORAGE+json.data
          a.click()
        }else{
          const e = json.error || "Không thể tạo báo cáo !!!"
          this.notice.error(e)
        }
      }).catch(e=>{
        this.notice.error(e)
      }).finally(()=>{
        this.loading = false
      })
    },
    CountTotal: function(packages){
      return CountTotal(packages)
    },
    Status: function(Status){
      return (Status) ? {color:'red',text:'Khóa'}:{color:'green',text:'Mở'}
    },
    async LayDuLieu() {
      this.req.fetch(this.shipment._id).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.items = json.data
        }else{
          const msg = json.error || "Lỗi kết nối máy chủ !!!"
          this.notice.error(msg)
          this.$router.push('/')
        }
      })
      .catch(err=>{
        this.notice.error(err)
      })
    },
    TimKiem() {

    },
    Them(data) {
      this.req.create(data).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.boxAdd = false
          this.notice.success("Đã thêm mới dữ liệu !!!");
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể thêm mới dữ liệu !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
    },
    Xoa(item){
      this.req.delete(item._id).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.notice.success("Đã xóa dữ liệu !!!");
          this.LayDuLieu()
        }else{
          const msg = json.error || "Không thể xóa dữ liệu !!!"
          this.notice.error(msg);
        }
      })
      .catch(err=>{
        this.notice.error(err);
      })
    }
  },
  mounted(){
    // this.LayDuLieu()
  },
  props: ["shipment","items", "Load"]
}
</script>
