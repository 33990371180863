var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team"},[_c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"sm12":"","xs12":"","md12":"","lg12":""}},[[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#0f2e8c","flat":""}},[_c('h3',{staticClass:"white--text"},[_vm._v("Danh sách hawb "+_vm._s(_vm.shipment.Code))])]),_c('v-card-title',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Tìm kiếm","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.DanhSachMoi,"search":_vm.search,"loading-text":"Đang tải dữ liệu...","footer-props":{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]},"items-per-page":20},scopedSlots:_vm._u([{key:"item.Documents",fn:function(ref){
var item = ref.item;
return [_c('v-list',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Tài liệu")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.ListDocumentStatus),function(stt,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item['DocumentUploaded'][stt.code] ? 'green':'red'}},[_vm._v(" "+_vm._s(item['DocumentUploaded'][stt.code] ? 'mdi-check-circle':'mdi-cancel')+" ")])],1),_c('v-list-item-content',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({domProps:{"textContent":_vm._s(stt.text)}},'v-list-item-title',attrs,false),on))]}}],null,true)},[_c('v-list',[(!item['DocumentUploaded'][stt.code])?_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"dark":"","color":"green"},on:{"click":function($event){return _vm.openUpload(item,stt.code)}}},[_vm._v(" Tải lên ")])],1)],1):_vm._e(),(_vm.loginInfo._perm === 'admin' && item['DocumentUploaded'][stt.code])?_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"dark":"","color":"blue"},on:{"click":function($event){return _vm.doLoadDocument(item._id, stt.code)}}},[_vm._v(" Xem tài liệu ")])],1)],1):_vm._e(),(_vm.loginInfo._perm === 'admin' && item['DocumentUploaded'][stt.code])?_c('v-list-item',[_c('v-list-item-title',[(item['DocumentUploaded'][stt.code])?_c('v-btn',{attrs:{"dark":"","color":"orange"},on:{"click":function($event){return _vm.doRemoveDocument(item._id, stt.code)}}},[_vm._v(" Xóa tài liệu ")]):_vm._e()],1)],1):_vm._e()],1)],1)],1)],1)}),1)],1)]}},{key:"item.Code",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":item.Locked ? 'orange':'primary'},on:{"click":function($event){return _vm.Load(item._id)}}},[_vm._v(" "+_vm._s(item.Code)+" ")])]}},{key:"item.Locked",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":_vm.Status(item.Locked).color}},[_vm._v(_vm._s(_vm.Status(item.Locked).text))])]}},{key:"item.MaxWeight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Weight)+" / "+_vm._s(item.MaxWeight)+" ")]}},{key:"item.totalItems",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalItems)+" ")]}},{key:"item._id",fn:function(ref){
var item = ref.item;
return [_c('Manage',{attrs:{"item":item,"controls":_vm.CustomControls(item)}})]}}],null,true)}),_c('v-toolbar',{attrs:{"color":"#0f2e8c","flat":""}},[_c('h3',{staticClass:"white--text"},[_vm._v("Danh sách hawb "+_vm._s(_vm.shipment.Code))])])],1)]],2)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.boxDocument),callback:function ($$v) {_vm.boxDocument=$$v},expression:"boxDocument"}},[_c('v-card',[_c('v-card-title',[_vm._v("Tải lên tài liệu - Hawb: "+_vm._s(_vm.DocumentHawbCode))]),_c('v-card-text',[_c('v-file-input',{attrs:{"show-size":"","truncate-length":"15","label":"Tải lên"},model:{value:(_vm.DocumentFile),callback:function ($$v) {_vm.DocumentFile=$$v},expression:"DocumentFile"}}),_c('br'),_c('v-select',{attrs:{"items":_vm.ListDocumentStatus,"item-value":"code","item-text":"text","label":"Loại tài liệu"},model:{value:(_vm.DocumentType),callback:function ($$v) {_vm.DocumentType=$$v},expression:"DocumentType"}}),_c('br')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){_vm.boxDocument = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"dark":"","color":"green","disabled":_vm.UploadState || _vm.DocumentFile === null,"loading":_vm.UploadState},on:{"click":_vm.doUpload}},[_vm._v("Tải lên")])],1)],1),_c('Loading',{attrs:{"status":_vm.UploadState}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }