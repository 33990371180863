<template>
  <div class="team">
    <v-container >
      <v-layout row wrap>
        <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat><h3 class="white--text">Thông tin kiện hàng</h3></v-toolbar>
              <v-card-text>
                <v-row>
                 <v-col
                    cols="6"
                    lg="3"
                    md="3"
                    xl="3"
                >
                  <v-text-field
                    v-model="packageDetail.PackageName"
                    label="Mã Kiện Hàng"
                    disabled
                  />
										 <div id="printMe" v-show="false">
											 <span class="center">
												 <p>Mã kiện hàng</p>
												 <h2 style="display:inline;">{{packageName(packageDetail.PackageName)[0]}}-</h2><h1 style="display:inline;">{{packageName(packageDetail.PackageName)[1]}}-{{packageName(packageDetail.PackageName)[2]}}</h1>
											 </span>
										 </div>
                </v-col>
                <v-col
                    cols="6"
                    lg="3"
                    md="3"
                    xl="3"
                >
                  <v-select
                    v-model="packageDetail.Type"
                    :items="PackageType"
                    item-value="code"
                    item-text="text"
                    :rules="[required('Loại')]"
                    label="Loại"
                  />
                </v-col>
                <v-col
                    cols="6"
                    lg="3"
                    md="3"
                    xl="3"
                >
                  <v-text-field
                    v-model="packageDetail.NW"
                    label="NW"
                    disabled
                    @change="TinhTien"
                  />
                </v-col>
                <v-col
                    cols="6"
                    lg="3"
                    md="3"
                    xl="3"
                >
                  <v-text-field
                    v-model="packageDetail.GW"
                    label="GW (Kg)"
                    @change="TinhTien"
                  />
                </v-col>
                <v-col
                    cols=6
                    md=2
                    lg=1
                    xl=1
                  >
                    <v-text-field
                      v-model="packageDetail.D"
                      label="Dài (cm)"
                      @change="TinhToanNW"
                    />
                  </v-col>
                  <v-col
                    cols=6
                    md=2
                    lg=1
                    xl=1
                  >
                    <v-text-field
                      v-model="packageDetail.R"
                      label="Rộng (cm)"
                      @change="TinhToanNW"
                    />
                  </v-col>
                  <v-col
                    cols=6
                    md=2
                    lg=1
                    xl=1
                  >
                    <v-text-field
                      v-model="packageDetail.C"
                      label="Cao (cm)"
                      @change="TinhToanNW"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md=2
                    lg=1
                    xl=1
                  >
                    <v-text-field
                      :value="items.length"
                      label="Số lượng"
                      disabled
                    />
                  </v-col>
                  <v-col
                    cols="6"
                    md=2
                    lg=1
                    xl=1
                  >
                    <v-text-field
                      :value="items.length"
                      label="PCS"
                      disabled
                    />
                  </v-col>
                  <!-- <v-col
                    cols="6"
                    lg="2"
                    md="1"
                    xl="1"
                  >
                    <v-currency-field
                      v-model="packageDetail.Cost"
                      label="Chi phí (Tạm tính)"
                    />
                  </v-col> -->
                  <v-col
                    cols="auto"
										v-if="LimitSale"
                  >
                    <v-btn large color="primary" @click="SavePackageData" :disabled="shipment.Status !== 'dang_xu_ly'">Lưu</v-btn>
                  </v-col>
									<v-col
										cols="auto"
										v-if="LimitSale"
									>
										<v-btn large color="green" @click="doPrint(packageDetail)" dark>In</v-btn>
									</v-col>
                  <v-col cols="6" md=2 lg=2 xl=2 v-if="LimitSale">
                    <v-file-input
                      ref="inputFile"
                      show-size
                      label="Tải hình ảnh"
                      accept="image/*"
                      @change="selectFile"
                    />
                  </v-col>
                  <v-col cols="6" md=3 lg=3 xl=3 v-if="packageDetail.Image">
                    <v-img :src="LinkStorage(packageDetail.Image)" aspect-ratio="1.3" contain />
                  </v-col>
                </v-row>
                <v-overlay
                  absolute
                  :value="loading"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="loading"
                  />
                </v-overlay>
              </v-card-text>
              <Loading :status="loadingUpdate" />
            </v-card>
            <br />
          </template>
         </v-flex>
         <v-flex
            sm12
            xs12
            md12
            lg12
						v-if="LimitSale"
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách hàng hóa</h3>
                <v-spacer />
                <v-btn-toggle v-if="shipment.Status === 'dang_xu_ly'">
                  <v-btn color="#00C853" dark @click="boxAdd = true">Thêm</v-btn>
                  <v-spacer class="spacer_lo_hang_2"></v-spacer>
                  <v-btn color="#00B0FF" dark @click="SavePackageItems">Lưu</v-btn>
                </v-btn-toggle>
              </v-toolbar>
              <v-card-title>
                <v-row>
                  <v-col cols=4>
                    <h5>Click vào số lượng để cập nhật</h5>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="2"
                    xl="2"
                  >
                    <v-btn
                      color="red"
                      dark
                      :disabled="selected.length === 0 || shipment.Status !== 'dang_xu_ly'"
                      @click="XoaNhieu"
                    >
                      Xóa ({{selected.length}} SP)
                    </v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Tìm kiếm"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-responsive max-height="600px" aspect-ratio="16/9" class="overflow-y-auto">
                <v-data-table
                  v-model="selected"
                  :loading="loading"
                  :headers="headers"
                  :items="DanhSachMoi"
                  :search="search"
                  :footer-props="{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
                  :items-per-page="20"
                  loading-text="Đang tải dữ liệu..."
                  item-key="Item._id"
                  show-select
                >
                  <template v-slot:[`item.countryCode`]="{ item }" >
                    {{Country(item.countryCode)}}
                  </template>
									<template v-slot:[`item.name`]="{ item }" >
										{{item.name}}
										<br />
										<v-btn
											x-small
											text
											fab
											color="blue"
											dark
											@click="doEditItem(item,'name')"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</template>
									<template v-slot:[`item.jaName`]="{ item }" >
										{{item.jaName}}
										<br />
										<v-btn
											x-small
											text
											fab
											color="blue"
											dark
											@click="doEditItem(item,'jaName')"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</template>
									<template v-slot:[`item.viName`]="{ item }" >
										{{item.viName}}
										<br />
										<v-btn
											x-small
											text
											fab
											color="blue"
											dark
											@click="doEditItem(item,'viName')"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</template>
                  <template v-slot:[`item.SL`]="{ item }" >
                    <v-btn text small @click="ShowBoxSoLuong(item)">{{ GetSL(item.index) }}</v-btn>
                  </template>
                  <template v-slot:[`item.description`]="{ item }">
										<v-btn
											color="primary"
											dark
											@click="doEditItem(item,'description')"
											small
										>
											Chi tiết
										</v-btn>
                  </template>
                  <template v-slot:[`item.viDescription`]="{ item }">
										<v-btn
											color="primary"
											dark
											@click="doEditItem(item,'viDescription')"
											small
										>
											Chi tiết
										</v-btn>
                  </template>
                  <template v-slot:[`item.enDescription`]="{ item }">
										<v-btn
											color="primary"
											dark
											@click="doEditItem(item,'enDescription')"
											small
										>
											Chi tiết
										</v-btn>
                  </template>
                  <template v-slot:[`item.productImages`]="{ item }">
                    <v-carousel height="200px" width="200px" hide-delimiters>
                      <v-carousel-item
                        v-for="(img,index) in item.productImages"
                        :key="index"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      >
                        <v-img :src="LinkIMG(img.uri)" aspect-ratio="1.3" contain />
                      </v-carousel-item>
                    </v-carousel>
                  </template>
                  <template v-slot:[`item._id`]="{ item }">
                    <template v-if="shipment.Status === 'dang_xu_ly'">
                      <v-btn color="red" @click="Xoa(item)" dark>
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                  </template>
                </v-data-table>
              </v-responsive>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">Danh sách hàng hóa</h3>
              </v-toolbar>
                <v-overlay
                  absolute
                  :value="loadingData"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="loadingData"
                  />
                </v-overlay>
            </v-card>
          </template>
         </v-flex>

      </v-layout>
    </v-container>
    <Add :enable.sync="boxAdd" :added.sync="triggerAdded" :nums.sync="Nums" :loading="SearchItem" :items="searchItems" :list="DanhSachMoi" :sl="packageSL" :Search="TimItem" :controls="Controls" :loadingItems="loadingItems" :uploadProcess="progress" :submitItem="createItems" ref="addBox" />
    <BoxSL :enable.sync="boxSoluong" :nums.sync="CapNhat.Nums" :loading="loadingSoluong" :submitForm="CapNhatSoLuong" />
		<v-dialog
			v-model="boxEditItem"
			maxWidth="600px"
		>
			<v-card>
				<v-card-title>
					<span class="headline">Sửa thông tin</span>
				</v-card-title>
				<v-card-text>
					<v-textarea
						v-model="editItemData.content"
					/>
				</v-card-text>
				<v-card-actions>
					<v-btn
						color="green"
						dark
						@click="doUpdateItem"
					>
						Lưu
					</v-btn>
					<v-spacer />
					<v-btn
						dark
						@click="boxEditItem = false"
					>
						Hủy
					</v-btn>
				</v-card-actions>
			</v-card>
			<Loading :status="loaderItem" />
		</v-dialog>
	</div>
</template>

<script>
import validations from '@/plugins/helper/validations'
import { Request } from '@/plugins/helper/fetch'
import { Status } from '@/plugins/helper/dstatus'
import { tinhtien, Country, LinkIMG } from '@/plugins/helper/string'
import Add from '@/components/detail/add.vue'
import BoxSL from '@/components/detail/soluong.vue'
// import Control from '@/components/base/thaotac.vue'
import Dialog from '@/components/base/Dialog.vue'
import Loading from '@/components/base/Loading.vue'
import {mapGetters} from "vuex";
import {getPDF} from "../plugins/query/package";
import printJS from "print-js";
import {qrSave} from "../plugins/query/item";

export default {
  name: 'detail',
  components: {
    Add,
    // Control,
    BoxSL,
    Dialog,
    Loading,
  },
  data () {
    return {
			editItemData:{
				content: null,
				id:null,
				type: null,
			},
			boxEditItem: false,
			loaderItem: false,
      notice: new Status(this.$swal),
      packageID: '',
      loadingUpdate: false,
      selected: [],
      filter: [{code:'PhoneJP',text:'SĐT JP'},{code:'PhoneVN',text:'SĐT VN'}],
      loading: false,
      loadingData: false,
      loadingSoluong: false,
      loadingItems: false,
      search: '',
      reqPackage: new Request('package'),
      req: new Request('detail'),
      headers: [
        { value:'data-table-select', text: '', fixed: true, width: '75px',class:"grey lighten-2 black--text" },
        { text: '#', value: 'index', align: 'center',width: "75px",class:"grey lighten-2 black--text" },
        { text: 'SL', value: 'SL', width: "75px", fixed: true ,class:"grey lighten-2 black--text" },
        { text: 'Jan Code', value: 'janCode', align: 'center',width: "150px",class:"grey lighten-2 black--text" },
        { text: 'Images', value: 'productImages', align: 'left',width: "300px",class:"grey lighten-2 black--text" },
        { text: 'EN Name', value: 'name', align: 'center',width: "150px",class:"grey lighten-2 black--text" },
        { text: 'JP Name', value: 'jaName', align: 'center',width: "150px",class:"grey lighten-2 black--text" },
        { text: 'VI Name', value: 'viName', align: 'center',width: "150px",class:"grey lighten-2 black--text" },
        { text: 'Price', value: 'price', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Origin', value: 'countryCode', align: 'center',width: "100px",class:"grey lighten-2 black--text" },
        { text: 'Description', value: 'description', align: 'left',width: "200px",class:"grey lighten-2 black--text" },
        { text: 'EN Description', value: 'enDescription', align: 'left',width: "200px",class:"grey lighten-2 black--text" },
        { text: 'VI Description', value: 'viDescription', align: 'left',width: "200px",class:"grey lighten-2 black--text" },
        { text: 'Thao tác', value: '_id', align: 'left',width: "200px",fixed:true ,class:"grey lighten-2 black--text" },
      ],
      searchItems: [],
      packageItems:[],
      packageSL:[],
      boxAdd: false,
      boxSoluong: false,
      packageDetail:{
        Type: '',
        Cost: 0,
        NW: 0,
        GW: 0,
        D: 0,
        R: 0,
        C: 0,
      },
      PackageType: [
        {code:'carton',text:'Loose carton'},
        {code:'pallet',text:'Pallet'},
        {code:'crate',text:'Wooden crate'},
        {code:'bag',text:'Bag'},
      ],
      Sales: [],
      CustomerCounter: {},
      SearchItem: false,
      Controls:[
        {color:'blue',text:'Chọn',do:this.Them},
        {color:'green',text:'Sửa',do:this.SuaItem},
        // {color:'red',text:'Link',do:this.OpenItem},
      ],
      ControlsList:[
        {color:'blue',text:'Số lượng',do:this.BoxSoLuong},
        {color:'red',text:'Link',do:this.OpenItem},
        {color:'red',text:'Xóa',do:this.Xoa},
      ],
      Nums: 0,
      CapNhat: {
        ID: null,
        Nums: 0
      },
      triggerAdded: false,
      currentFile: undefined,
      progress: 0,
    }
  },
  computed: {
		...mapGetters(['authStatus','isAuthenticated','loginInfo','packageFilter']),
		LimitSale(){
			const {loginInfo} = this;
			return loginInfo._perm === 'admin' || loginInfo._perm === 'manager' || loginInfo._perm === 'warehouse' || loginInfo._perm === 'checker'
		},
    DanhSachMoi: function(){
        return this.package.Items.map(
          (items, index) => {
            const r = {
              ...items,
              index: index+1
            }
            return r
          })
      },
  },
  watch:{
    currentFile(v){
      if(v.name !== undefined){
        this.TaiHinhAnh()
      }
    }
  },
  methods: {
    ...validations,
    Country:Country,
    LinkIMG:LinkIMG,
		doUpdateItem(){
			const {id, content, type} = this.editItemData;
			if(id && content && type){
				this.loaderItem = true;
				qrSave(id,{content, type}).then(resp=>{
					const {success, error} = resp;
					if(error){
						this.notice.error(error);
					}else{
						const index = this.package.Items.findIndex(item=>item._id === id);
						if(index > -1){
							this.package.Items[index][type] = content;
						}
						this.notice.success('Đã cập nhật dữ liệu !!!');
						this.boxEditItem = false;
					}
				}).finally(()=>{
					this.loaderItem = false;
				})
			}
		},
		doEditItem(item,type){
			this.editItemData = {
				content: item[type],
				type: type,
				id: item._id
			}
			this.boxEditItem = true;
		},
		doPrint(details){
			const {_id} = details;
			this.loading = true;
			getPDF({id:_id}).then(resp=>{
				const {error} = resp;
				if(error){
					this.notice.error(error);
				}else{
					const pdfUrl = URL.createObjectURL(resp);
					printJS(pdfUrl);
				}
			}).finally(()=>{
				this.loading = false;
			})
		},
		packageName: function(name){
			if(name) {
				if (name.indexOf('-') !== -1) return name.split('-');
				return name;
			}
			return ["",""];
		},
    selectFile: function(file) {
      this.progress = 0;
      this.currentFile = file;
    },
    createItems(submitData,selected=false){
      this.loadingItems = true
      const {janCode} = submitData
      this.req.UploadItem(janCode,submitData,(event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      },'createItem').then(axios=>{
        const {success,error,data} = axios.data
        if(success){
          this.notice.success("Đã tạo Jan Code thành công !!!")
					if(selected){
						this.Them(data)
					}
        }else{
          const e = error || "Không thể tạo Jan Code !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingItems = false
      })
    },
    LinkStorage(IMG){
      return `https://statics.tomexpress.jp/tomexpress/${IMG}`
    },
    TaiHinhAnh(){
      this.loadingUpdate = true
      this.reqPackage.Upload(this.packageDetail._id,this.currentFile,(event) => {
              this.progress = Math.round((100 * event.loaded) / event.total);
      }).then(axios=>{
        const {success,error} = axios.data
        if(success){
          this.notice.success("Đã tải hình ảnh thành công !!!")
          this.currentFile = undefined
          this.progress = 0
          this.$refs.inputFile.reset()
        }else{
          const e = error || "Không thể tải lên hình ảnh !!!"
          this.notice.error(e)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingUpdate = false
      })
    },
    GetSL(index){
      const ID = index-1
      return this.packageSL[ID]
    },
    DoiSoLuong(data){
      const ID = data.index-1
      this.CapNhat = {ID:ID, Nums: this.packageSL[ID]}
    },
    ShowBoxSoLuong(data){
      const ID = data.index-1
      this.CapNhat = {ID:ID, Nums: this.packageSL[ID]}
      this.boxSoluong = true
    },  
    TinhTien(){
      const cost = this.packageDetail.NW > this.packageDetail.GW ? this.packageDetail.NW:this.packageDetail.GW
      this.packageDetail.Cost = tinhtien(cost,75000)
    },
    TinhToanNW(){
      const ThongTin = {
        D: parseInt(this.packageDetail.D),
        R: parseInt(this.packageDetail.R),
        C: parseInt(this.packageDetail.C),
      }
      if(parseInt(ThongTin.D) === 0 || parseInt(ThongTin.R) === 0 || parseInt(ThongTin.C) === 0) return
      const the_tich = parseInt(ThongTin.D)*parseInt(ThongTin.R)*parseInt(ThongTin.C)
      //this.packageDetail.NW = Math.ceil(parseInt(the_tich)/6000)
			this.packageDetail.NW = parseFloat(the_tich/6000).toFixed(2);
      this.TinhTien()
    },
    Status: function(Status){
      return (Status) ? {color:'red',text:'Khóa'}:{color:'green',text:'Mở'}
    },
    CapNhatSoLuong(){
      this.packageSL[this.CapNhat.ID] = this.CapNhat.Nums
      this.packageDetail.SL[this.CapNhat.ID] = this.CapNhat.Nums
      this.boxSoluong = false
      // this.loadingSoluong = true
      // this.req.update(this.CapNhat.ID,{Nums:this.CapNhat.Nums}).then(async resp=>{
      //   const json = await resp.json()
      //   if(json.success === true){
      //     this.LayDuLieu()
      //     this.boxSoluong = false
      //     this.notice.success("Đã cập nhật !!!")
      //   }else{
      //     const msg = json.error || "Không thể tìm item !!!"
      //     this.notice.error(msg)
      //   }
      // })
      // .catch(e=>{
      //   this.notice.error(e)
      // })
      // .finally(()=>{
      //   this.loadingSoluong = false
      // })
    },
    TimItem(janCode) {
      if(!janCode) return
      this.SearchItem = true
      this.req.search('searchItem',{janCode:janCode}).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.searchItems = json.data
        }else{
          const msg = json.error || "Không thể tìm item !!!"
          this.notice.error(msg)
        }
      })
      .catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.SearchItem = false
      })
    },
    OpenItem(data){
      console.log("Open: "+data)
    },
    SearchInArray(nameKey, myArray, Key){
        for (var i=0; i < myArray.length; i++) {
            if (myArray[i][Key] === nameKey) {
                return myArray[i];
            }
        }
    },
    Them(data) {
			if(parseInt(this.Nums) > 0) {
				const isFound = this.packageItems.find((item) => item === data._id)
				if (isFound !== undefined) {
					const index = this.packageItems.indexOf(isFound)
					const SoLuongCu = parseInt(this.packageSL[index])
					this.packageSL[index] = SoLuongCu + parseInt(this.Nums)
					this.packageDetail.SL[index] = SoLuongCu + parseInt(this.Nums)
					// return this.notice.error("Sản phẩm này đã có trong danh sách. Vui lòng cập nhật số lượng !!!")
				} else {
					this.packageItems.push(data._id)
					this.packageSL.push(this.Nums)
					this.packageDetail.Items.push(data)
					this.packageDetail.SL.push(this.Nums)
				}
				this.triggerAdded = true
				this.searchItems = []
				// this.notice.success("Đã thêm thành công !!!")
				this.Nums = 0
			}else{
				this.notice.error('Số lượng phải lớn hơn 0');
			}
    },
    XoaNhieu(){
      this.selected.forEach((item)=>{
        this.Xoa(item)
      })
      this.selected = []
    },
    Xoa(item){
      const ID = item.index-1
      this.packageItems.splice(ID,1)
      this.packageSL.splice(ID,1)
      this.packageDetail.Items.splice(ID,1)
      // this.req.delete(item._id).then(async resp=>{
      //   const json = await resp.json()
      //   if(json.success === true){
      //     this.notice.success("Đã xóa dữ liệu !!!");
      //     this.LayDuLieu()
      //   }else{
      //     const msg = json.error || "Không thể xóa dữ liệu !!!"
      //     this.notice.error(msg);
      //   }
      // })
      // .catch(err=>{
      //   this.notice.error(err);
      // })
    },
    SavePackageItems(){
      this.reqPackage.update(this.packageDetail._id,{Items:this.packageItems,SL:this.packageSL}).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.notice.success(`Đã cập nhật thông tin kiện hàng  ${this.packageDetail.PackageName}`)
          this.$emit("update:update", true);
        }else{
          const msg = json.error || `Không thể cập nhật kiện hàng  ${this.packageDetail.PackageName}`
          this.notice.error(msg)
        }
      }).catch(e=>{
        this.notice.error(e)
      })
    },
    SavePackageData(){
      const updateData = (({
        Type,
        Cost,
        NW,
        GW,
        D,
        R,
        C,
      }) => ({
        Type,
        Cost,
        NW,
        GW,
        D,
        R,
        C,
      }))(this.packageDetail)
      this.loadingUpdate = true
      this.reqPackage.update(this.packageDetail._id,updateData).then(async resp=>{
        const json = await resp.json()
        if(json.success === true){
          this.notice.success(`Đã cập nhật thông tin kiện hàng  ${this.packageDetail.PackageName}`)
          this.$emit("update:update", true);
        }else{
          const msg = json.error || `Không thể cập nhật kiện hàng ${this.packageDetail.PackageName}`
          this.notice.error(msg)
        }
      }).catch(e=>{
        this.notice.error(e)
      })
      .finally(()=>{
        this.loadingUpdate = false
      })
    },
		async print() {
			// Pass the element id here
			await this.$htmlToPaper('printMe');
		}
  },
  mounted(){
    this.packageDetail = this.package
    this.packageItems = this.items.Item
    this.packageSL = this.items.SL
    // this.LayDuLieu()
  },
  props:["package","items","sl","update","shipment"]
}
</script>
<style scoped>
  .truncate {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>